import React, { useContext, useState, useEffect } from "react";
import { Button, Typography } from "@material-ui/core";
import { Row, Col, Container } from "reactstrap";
import { multiStepContextAlu } from "../../StepContext";
import { makeStyles } from "@material-ui/styles";
import "../styles/Option.css";
import "../styles/panel.css";
import "../styles/RadioButtonStyle.css";
import aluprofExcl from "../panels/exclusive/aluprof_exclusive.png";
import aluprofBasic from "../panels/basic/basic.png";
import aluprofBasicwithGlass from "../panels/basic/basic_mit_glass.png";
import { checkRequiredFields } from "../functions";
const useStyles = makeStyles((theme) => ({
  subheader: {
    marginTop: 100,
  },
}));
export default function Fstep() {
  const { setStep, userData, setUserData, urlData, setUrlData } =
    useContext(multiStepContextAlu);
  const [error, seterror] = useState("");
  const [required, setrequired] = useState(false);
  useEffect(() => {
    const check = checkRequiredFields([userData["panel"]]);
    setrequired(check);
  });
  const handleNext = () => {
    required ? setStep(6) : seterror(`Fields cannot be empty`);
  };
  const classes = useStyles;
  var basicPanel = aluprofBasic;
  (userData["model"]+'').includes('Verglasung')
    ? (basicPanel = aluprofBasicwithGlass)
    : (basicPanel = aluprofBasic);
  return (
    <Container maxWidth="md">
      <span className="requiredWarning">{error}</span>
      <Row>
        <Col>
          <Row>
            <Col>
              <Typography
                variant="h4"
                color="primary"
                align="left"
                className={classes.subheader}
              >
                Basic
              </Typography>
              <label
                className="drinkcard-cc-panel"
                style={{ backgroundImage: "url(" + basicPanel + ")" }}
                htmlFor="leftOut"
              ></label>
            </Col>
            <Col style={{ marginTop: "80px" }}>
              <label className="container-radio">
                AluProf EINSATZFÜLLUNG
                <input
                  type="radio"
                  value="AluProf EINSATZFÜLLUNG"
                  name="panel"
                  onChange={(e) => {
                    setUserData({
                      ...userData,
                      panel: e.target.value + " (Basic)",
                    });
                    setUrlData({ ...urlData, panelUrl: basicPanel });
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </Col>
          </Row>
          {userData["model"] !== "mit Glas" ? (
            <Row>
              <Col>
                <Typography
                  variant="h4"
                  color="primary"
                  align="left"
                  className={classes.subheader}
                >
                  Exclusive
                </Typography>

                <label
                  className="drinkcard-cc-panel"
                  style={{ backgroundImage: "url(" + aluprofExcl + ")" }}
                  htmlFor="leftOut"
                ></label>
              </Col>
              <Col style={{ marginTop: "80px" }}>
                <label className="container-radio">
                  AluProf MB-86 BEIDSEITIG FLÜGELÜBERDECKEND
                  <input
                    type="radio"
                    value="AluProf MB-86 BEIDSEITIG FLÜGELÜBERDECKEND "
                    name="panel"
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        panel: e.target.value + " (Exclusive)",
                      });
                      setUrlData({ ...urlData, panelUrl: aluprofExcl });
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
              </Col>
            </Row>
          ) : null}
        </Col>
        <Col>
          {required ? (
            <div style={{ display: "block" }} ma>
              <h6 style={{ color: "#444" }}>Ausgewählt</h6>

              <img
                src={urlData["panelUrl"]}
                width="100px"
                height="90px"
                alt={userData["panel"]}
              />
              <p style={{ width: "300px", marginLeft: "150px" }}>
                {userData["panel"]}
              </p>
            </div>
          ) : null}
        </Col>
      </Row>

      <div>
        <Button
          className="buttonBack"
          margin="normal"
          onClick={() => setStep(4)}
          variant="contained"
          color="secondary"
        >
          {" "}
          Back{" "}
        </Button>
        <Button
          className="buttonNext"
          margin="normal"
          onClick={handleNext}
          variant="contained"
          color="primary"
        >
          {" "}
          Next{" "}
        </Button>
      </div>
    </Container>
  );
}
