import React from "react";

import MaterialTable from "material-table";
import { Typography, TextField } from "@material-ui/core";

import Autocomplete from "@mui/material/Autocomplete";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { Filter } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
function createData(
  date,
  ofr,
  commision,
  profil,
  fenster,
  aufsatz,
  varbou,
  price,
  ship_date
) {
  return { date, ofr, commision, profil, fenster, aufsatz, varbou, price,ship_date };
}

export default class CompletedOrders extends React.Component {
  ranger = {};
  constructor(props) {
    super(props);
    // Initializing the state

    this.state = {
      users: [],
      value: "",
      inputValue: "",
      filtered: [],
      dateRange: { start: new Date(), end: new Date() },
      togglePickerDisplay: false,
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.changeDisplayRangePicker = this.changeDisplayRangePicker.bind(this);
  }

  handleSelect(ranges) {
    console.log(ranges)
    const selectedDate = {
      start: ranges.selection.startDate,
      end: ranges.selection.endDate,
    };
    this.setState({ dateRange: selectedDate });
  }
  getUsers = async (callback) => {
    try {
      const response = await fetch(
        "https://gwadminapi.herokuapp.com/getusers",
         {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      }).then((res) => {
        res.json().then((elm) => {
          this.setState({ users: elm });
        });
      });
    } catch (e) {
      console.error("Error: ", e);
    }
  };
  componentDidMount() {
    this.getUsers();
  }

  appendSpreadsheet = async (cusName) => {
    try {
      console.log(this.state.dateRange)
      const response = await fetch(
        "https://sheetsgw.herokuapp.com/production/completedrows/",
        {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },

          body: this.state.togglePickerDisplay
            ? JSON.stringify({
                customerName: cusName,
                range: this.state.dateRange,
              })
            : JSON.stringify({ customerName: cusName, range: { start: "Wed Dec 31 2021 00:00:00 GMT+0100 (Central European Standard Time)", end: "Wed Dec 31 2023 00:00:00 GMT+0100 (Central European Standard Time)" } ,
            }),
        }
      ).then((res) => {
        res.json().then((elm) => {
          this.setState({ filtered: elm });
        });
      });
    } catch (e) {
      console.error("Error: ", e);
    }
  };
  changeDisplayRangePicker = () => {
    this.setState({ togglePickerDisplay: !this.state.togglePickerDisplay });
  };
  render() {
    let price = 0.0;
    this.state.filtered.map(
      (prc) => (price += parseFloat((prc.price + "").replace(",", ".")))
    );

    const selectionRange = {
      startDate: this.state.dateRange.start,
      endDate: this.state.dateRange.end,
      key: "selection",
    };
    const MyNewTitle = ({ variant = "h6" }) => (
      <Typography
        variant={variant}
        style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
      >
        Price: {price.toFixed(2)}
      </Typography>
    );
    console.log(this.state.filtered)
    return (
      <div className="ml-5" style={{ width: "140%", marginLeft:"-80px"}}>
        <div>
          <Typography variant="h4" style={{ marginBottom: "2%" }}>
            {" TAMAMLANAN "}
            {this.props.name}
            {this.state.value} Produktionsverfolgung{" "}
          </Typography>
          <div className="mb-2 d-flex justify-content-start">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={this.state.users}
              sx={{ width: 300 }}
              value={this.state.value}
              onChange={(event, newValue) => {
                this.appendSpreadsheet(newValue);
                this.setState({ value: newValue });
              }}
              inputValue={this.setState.inputValue}
              onInputChange={(event, newInputValue) => {
                this.setState({ inputValue: newInputValue });
              }}
              renderInput={(params) => <TextField {...params} label="Klient" />}
            />

            <div id="dateRangePicker">
              {this.state.togglePickerDisplay && (
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={this.handleSelect}
                  dateDisplayFormat="dd-MMM-yyyy"
                />
              )}
            </div>
            <div className="d-flex flex-column gap-2">
              <Button
                style={{ height: "50px" }}
                onClick={this.changeDisplayRangePicker}
                variant="contained"
                color="primary"
              >
                {this.state.togglePickerDisplay ? "Hide" : "Show"} Picker
              </Button>
              {this.state.togglePickerDisplay && (
                <Button
                  style={{ height: "50px" }}
                  onClick={() => this.appendSpreadsheet(this.state.value)}
                  variant="contained"
                  className="bg-success text-white"
                >
                  Apply Filter
                </Button>
              )}
            </div>
          </div>
        </div>
        <div>
          <MaterialTable
      title={<MyNewTitle variant="h5"/>}
      columns={[
              {
                title: "Date",
                field: "date",
                cellStyle:{
                  fontSize:12
                },
                headerStyle:{
                  fontsize:12
                }
              },
              {
                title: "Lieferung",
                field: "ship_date",
                cellStyle:{
                  fontSize:12
                },
                headerStyle:{
                  fontsize:12
                }

              },
              {
                title: "Preis",
                field: "price",
                cellStyle:{
                  fontSize:12
                },
                headerStyle:{
                  fontsize:12
                }
              },
              
              {
                title: "Ofr",
                field: "ofr",
                cellStyle:{
                  fontSize:12
                },
                headerStyle:{
                  fontsize:12
                }
                
              },
              {
                title: "Name",
                field: "commision",
                cellStyle:{
                  fontSize:12
                },
                headerStyle:{
                  fontsize:12
                }
              },
              {
                title: "Profil",
                field: "profil",
                cellStyle:{
                  fontSize:12
                },
                headerStyle:{
                  fontsize:12
                }
              },
              {
                title: "Fenster",
                field: "fenster",
                cellStyle:{
                  fontSize:12
                },
                headerStyle:{
                  fontsize:12
                }
              },
              {
                title: "Aufsatz Rollladen",
                field: "aufsatz",
                cellStyle:{
                  fontSize:12
                },
                headerStyle:{
                  fontsize:12
                }
              },
              {
                title: "Varbou",
                field: "varbou",
                cellStyle:{
                  fontSize:12
                },
                headerStyle:{
                  fontsize:12
                }
              },

              {
                title: "Raffstore",
                field: "raffstore",
                cellStyle:{
                  fontSize:12
                },
                headerStyle:{
                  fontsize:12
                }
              },
              {
                title: "HSI",
                field: "hsi",
                cellStyle:{
                  fontSize:12
                },
                headerStyle:{
                  fontsize:12
                }
              },
              {
                title: "PAS/PSK",
                field: "pas_psk",
                cellStyle:{
                  fontSize:12
                },
                headerStyle:{
                  fontsize:12
                }
              },
              {
                title: "Alutüren",
                field: "aludoor",
                cellStyle:{
                  fontSize:12
                },
                headerStyle:{
                  fontsize:12
                }
              },
              {
                title: "Türen",
                field: "door",
                cellStyle:{
                  fontSize:12,
                },
                headerStyle:{
                  fontsize:12
                }
              },
            
            ]}
            data={this.state.filtered}
            options={{ pageSize: 10, sorting: true,fixedColumns:{
            },tableLayout:'auto',}}
            style={{ zIndex: "-1" }}
          />
        </div>
      </div>
    );
  }
}
