import React, { useContext, useState, useEffect } from "react";
import { Button, Typography } from "@material-ui/core";
import { multiStepContextAlu } from "../../StepContext";
import { Container, Row, Col } from "reactstrap";

import { checkRequiredFields, getFilenameFromUrl } from "../functions";
import { locks,rosettes } from "../StepFiles/EightStepFiles";
export default function Jstep() {

  const { setStep, userData, setUserData, urlData, setUrlData } =
    useContext(multiStepContextAlu);
 


  const [error, seterror] = useState("");
  const [required, setrequired] = useState(false);
  useEffect(() => {
    const check = checkRequiredFields([
   
      userData["rosette"],
      userData["lock"],
    ]);
    setrequired(check);
  });
  const handleNext = () => {
    required ? setStep(10) : seterror(`Fields cannot be empty`);
  };

  function importAll(r) {
    return r.keys().map(r);
  }
  function importImages(array, name) {
    var imageArray = [];
    array.map((element) => {
      imageArray.push([element, require("../accessories/" + name + "/" + element)]);
    });
    return imageArray;
  }

  function getName(filename) {
    let array = filename.split(".");
    let modelname = array[0].replace(/-|_/g, " ");;
    return modelname;
  }
  const newlockImages = importImages(locks,"locks");


  const newrosetteImages = importImages(rosettes,"rosette");


  return (
    <Container maxWidth="lg" >
      
      <Typography variant="h3" color="primary" >
              Zubehoer
              </Typography>  
      <span className="requiredWarning">{error}</span>

  <Row>
        <Col lg={3} md={3}>
        <Typography variant="h5" color="secondary" >
              Rosetten
              </Typography>  
          <div class="cc-selector-accessories">
            {newrosetteImages.map((image) => {
              var filename = getName(image[0]);

              return (
                <Row>
                  <Col sm={6} lg={6}>
                    <Row>
                      <div className="column">
                        <input
                          id={image[1].default}
                          type="radio"
                          name="Rosette"
                          onChange={(e) => {
                            setUserData({ ...userData, rosette: filename });
                            setUrlData({
                              ...urlData,
                              rosetteUrl: image[1].default,
                            });
                          }}
                        ></input>
                        <label
                          className="drinkcard-cc-accessory"
                          htmlFor={image[1].default}
                          style={{ backgroundImage: `url(${image[1].default})` }}
                        ></label>
                      </div>
                    </Row>
                  </Col>
<Col sm={6}  lg={6}>
<p >{filename}</p>
</Col>
                </Row>
              );
            })}
          </div>
        </Col>
        <Col lg={6} md={6}>
        <Typography variant="h5" color="secondary" >
              Verriegelunge
              </Typography>  
          <div class="cc-selector-accessories verriegelunge">
            <Container>
              {newlockImages.map((image) => {
                var filename = getName(image[0]);

                return (
                  <Row>
                    <Col sm="6">
                      <div className="column ">
                        <input
                          id={image[1].default}
                          type="radio"
                          name="Lock"
                          onChange={(e) => {
                            setUserData({ ...userData, lock: filename });
                            setUrlData({ ...urlData, lockUrl: image[1].default });
                          }}
                        ></input>
                        <label
                          className="drinkcard-cc-accessory locks"
                          htmlFor={image[1].default}
                          style={{ backgroundImage: `url(${image[1].default})` }}
                        >
                          {" "}
                        </label>
                      </div>
                    </Col>
                    <Col sm="6">
                      <p className="locksLabel">{filename}</p>
                    </Col>
                  </Row>
                );
              })}
            </Container>
          </div>
        </Col>
        <Col lg={2} md={2}>
        <Row style={{marginTop:"3rem"}}>
            {required ? (
              <div>
                <h6 style={{ color: "#444" }}>Ausgewählt</h6>
                <img
                  src={urlData["rosetteUrl"]}
                  width="80"
                  height="120"
                  alt={userData["rosette"]}
                />                      <p>{userData["rosette"]}</p>{" "}


<img
                        src={urlData["lockUrl"]}
                        width="50"
                        height="150"
                        alt={userData["lock"]}
                      ></img>
                      <p>
                        {userData["lock"]}
                      </p>
                      
              </div>
            ) : null}
          </Row>
        </Col>
        
      </Row>
      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <Button
            className="buttonBack"
            margin="normal"
            onClick={() => setStep(8)}
            variant="contained"
            color="Secondary"
          >
            {" "}
            Back{" "}
          </Button>
          <Button
            className="buttonNext"
            margin="normal"
            onClick={handleNext}
            variant="contained"
            color="primary"
          >
            {" "}
            Next{" "}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
