import React, { useRef,useState} from 'react'
import { Link,useHistory } from 'react-router-dom';
import { Form,FormGroup,Label,Input,Button,Card,CardBody, CardTitle,Container, Alert } from 'reactstrap';
import { useAuth } from './AuthContext';
export default function SignUp(){

    const [input, setInput] = useState('')
    const {signup} = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false)
    const history = useHistory();
    async function handleSubmit(e){
        e.preventDefault();

        if(input.password!== input.passwordConfirm){
            return setError('Passwords do not match');
        }
        try{
            setError('');
            setLoading(true)
            await signup(input.email, input.password)
            history.push('/signup')
        }catch(err){
            console.error(err);
            setError('Failed to create an acoount')
        }
        setLoading(false);
    }
    return (
        <>
        <Card>
            <CardBody>
            <CardTitle tag="h5">Sign Up</CardTitle>
            {error&& <Alert color="danger">{error}</Alert>   }
                <Form onSubmit={handleSubmit}>
                    <FormGroup id="email">
                        <Label >Email</Label >
                        <Input type="email" onChange={(event)=>
                                setInput({ ...input, email: event.target.value})

                        }  required/>
                    </FormGroup>
                    <FormGroup id="password">
                        <Label >Password</Label >
                        <Input  type="password" onChange={(event)=>
                                setInput({ ...input, password: event.target.value})

                        }  required/>
                    </FormGroup>
                    <FormGroup id="password-confirm">
                        <Label > Password Confirmation</Label >
                        <Input  type="password" onChange={(event)=>
                                setInput({ ...input, passwordConfirm: event.target.value})

                        } required/>
                    </FormGroup>
                    <Button disabled={loading} color="primary" type="submit">Sign Up</Button>
                </Form>
            </CardBody>
            <div className="w-100 text-center mt-2">
                Already have an account? <Link to="/login"> Log In</Link>
            </div>
        </Card>
        </>

    )
}