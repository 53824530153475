
import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { db } from "../firebase";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import PDF from "../aludoor/pdf";
export default class Orders extends React.Component {
  constructor(props) {
    super(props);
  
    // Initializing the state 
    this.state = {array:[],urlArray:[],urlEmpty:{
    
      "urlData": {
          "rosetteUrl": "",
          "outcolorUrl": "",
          "otsUrl": "",
          "cameraUrl": "",
          "incolorUrl": "",
          "outhandleUrl": "",
          "doorOptionUrl": "",
          "panelUrl": "",
          "lockUrl": "",
          "doorphoneUrl": "",
          "inhandleUrl": "",
          "eoffnerUrl": "",
          "glazingUrl4":"",
          "modelUrl":""
      }
    
  }};
  }
  
  componentDidMount() {
  
    this.fetchData();
  }
  fetchData =()=>{
  
    
    db.collection("doors").where("uid","==",this.props.user.uid).get().then((querySnapshot) => {

        querySnapshot.forEach((doc) => {
          this.setState((prevState)=> {
              return {
                  array:[...prevState.array,doc.data()],
                  urlArray:[...prevState.urlArray, doc.data().urlArray]
              }
          });
     
        });
    });
//     db.collection("submitted").orderBy('timestamp', 'desc').limit(5).get().then((querySnapshot) => {

//       querySnapshot.forEach((doc) => {
//         this.setState((prevState)=> {
//             return {
//                 array:[...prevState.array,doc.data()],
//                 urlArray:[...prevState.urlArray, doc.data().urlArray]
//             }
//         });
   
//       });
//   });
    
  }
  loadPage=(userData,urlData)=>{

    return(
        <PDF userData={userData} urlData={urlData}/>
    )
}

  render() {

    return (
        
          <TableContainer component={Paper}>
      <Table  size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell align="right">Commision</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Door Option</TableCell>
            <TableCell align="right">Size</TableCell>
            <TableCell align="right">Opening</TableCell>
            <TableCell align="right">Model</TableCell>
          
            <TableCell align="right">Panel</TableCell>
            <TableCell align="right">Glazing Shape</TableCell>
            {/* <TableCell align="right">Glazing</TableCell> */}
            {/* <TableCell align="right">Color</TableCell>
            <TableCell align="right">Inhandle</TableCell>
            <TableCell align="right">Outhandle</TableCell>
            <TableCell align="right">Rosette</TableCell>
            <TableCell align="right">Lock</TableCell>
            <TableCell align="right">Camera</TableCell>
            <TableCell align="right">Doorphone</TableCell>
            <TableCell align="right">OTS</TableCell>
            <TableCell align="right">E Offner</TableCell> */}
         
             <TableCell align="right">Note</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.array.map((row) => (
            <TableRow key={row.submittedarray.name} style={{overflowX: 'visible'}}>
              <TableCell component="th" scope="row">

          
            
                {row.submittedarray.customerName}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.submittedarray.commisionName}
              </TableCell>
              <TableCell align="right" >{row.submittedarray.email}</TableCell>
              <TableCell align="right">    <img
                    width="90px"
                    height="150px"
                    src={row.submittedarray.doorOptionWithLetters}
                    alt={row.submittedarray.doorOption}
                  ></img></TableCell>
              <TableCell align="right">     {row.submittedarray.dimension1 != null ? <p>a: {row.submittedarray.dimension1}</p> : null}
                    {row.submittedarray.dimension2 != null ? <p>b: {row.submittedarray.dimension2}</p> : null}
                    {row.submittedarray.dimension3 != null ? <p>c: {row.submittedarray.dimension3}</p> : null}
                    {row.submittedarray.dimension4 != null ? <p>d:{row.submittedarray.dimension4}</p> : null}
                    {row.submittedarray.dimension5 != null ? <p>d: {row.submittedarray.dimension5}</p> : null}</TableCell>
              {/* <TableCell align="right">{row.submittedarray.perspective}-
              <Col md={6}>
                  <img
                    src={row.submittedarray.openingIn}
                    width="70"
                    height="130"
                    alt={row.submittedarray.doorOption}
                  ></img>
                  <p class="OpeningStrStyle">{row.submittedarray.openingStr[1]}</p>

             
                </Col>
                <Col md={6}>
                  <img
                    src={row.submittedarray.openingOut}
                    width="70"
                    height="130"
                    alt={row.submittedarray.doorOption}
                  ></img>
                  <p class="OpeningStrStyle">{row.submittedarray.openingStr[0]}</p>
                </Col>              </TableCell> */}
           
<TableCell align="right">
<img src={row.submittedarray.modelUrl} width="50" height="100"></img>

{row.submittedarray.model}
</TableCell>
        
              <TableCell align="right">{row.submittedarray.panel}</TableCell>
              <TableCell align="right">    <img
                    width="90px"
                    height="150px"
                    src={row.submittedarray.doorOptionGlass}
                    alt={row.submittedarray.doorOption}
                  ></img></TableCell>
              <TableCell align="right">
              <div>
                    {row.submittedarray.glazing1 != null ? <p>a: {row.submittedarray.glazing1}</p> : null}
                    {row.submittedarray.glazing2 != null ? <p>b: {row.submittedarray.glazing2}</p> : null}
                    {row.submittedarray.glazing3 != null ? <p>c: {row.submittedarray.glazing3}</p> : null}
                    {row.submittedarray.glazing4 != null ? <p>d: {row.submittedarray.glazing4}</p> : null}
                  </div>
</TableCell>
              {/* <TableCell align="right">
                  In:{row.submittedarray.incolor} 
                  Out:{row.submittedarray.outcolor} 
</TableCell>
              <TableCell align="right">
             <img
                        src={row.submittedarray.inhandleUrl}
                        width="80"
                        height="120"
                        alt={row.submittedarray.inhandle}
                      /> 
               {row.submittedarray.inhandle}
                   </TableCell>
              <TableCell align="right">
              {row.submittedarray.outhandle} 
                   </TableCell>
              <TableCell align="right">
              {row.submittedarray.rosette}
                   </TableCell>
              <TableCell align="right">
              {row.submittedarray.lock} 
                   </TableCell>
              <TableCell align="right"> {row.submittedarray.camera}</TableCell>
              <TableCell align="right"> {row.submittedarray.doorphone}</TableCell>
              <TableCell align="right"> {row.submittedarray.ots}</TableCell>
              <TableCell align="right"> {row.submittedarray.eoffner}</TableCell>
              <TableCell align="right"> {row.submittedarray.doorphone}</TableCell> */}
              <TableCell align="right"> {row.submittedarray.note}</TableCell>
              <TableCell align="right">  
              {row.urlData?
               <Link to={{pathname:"/pdf",state:{userDataPdf:row.submittedarray,urlDataPdf:row.urlData}}} >
                <Button variant="contained" color="primary">
                  Details
                </Button>
              </Link>
              
              :  <Link to={{pathname:"/pdf",state:{userDataPdf:row.submittedarray,urlDataPdf:this.state.urlEmpty.urlData}}} >
              <Button variant="contained" color="primary">
                Details
              </Button>
            </Link>}</TableCell>
         
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  
    );
  }
}