import React, {useContext, useState} from "react";
import {Row, Col} from "reactstrap";
import { multiStepContextAlu } from "../StepContext";
import MaterialTable from "material-table";

export default function MaterialTableN() {
  const {userFsData, finalFsData, setFinalFsData, ofr} =
    useContext(multiStepContextAlu);
  const [data, setData] = useState(finalFsData);

  function subtotal(items) {
    return items
      .map(({ amount }) => parseInt(amount))
      .reduce((sum, i) => sum + i, 0);
  }

  const totalAmount = subtotal(finalFsData);

  return (
    <Row>
      <Row>
        <Col>
          <strong> Kommission:</strong>
          {userFsData["commisionName"]}
        </Col>
        <Col>
          <strong> OFR:</strong>
          {ofr}
        </Col>

        <Col>
          <strong>Amount: </strong> {totalAmount}
        </Col>
      </Row>
      <Row>
        <MaterialTable
          title=""
          columns={[
            {
              title: "Typ",
              field: "type",
            },
            {
              title: "Breite(mm)",
              field: "width",
            },
            {
              title: "Höhe(mm)",
              field: "height",
            },
            {
              title: "Anzahl",
              field: "amount",
            },
            {
              title: "Farbe",
              field: "color",
            },
          ]}
          data={finalFsData}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  setData([...finalFsData, newData]);

                  resolve();
                }, 1000);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataUpdate = [...finalFsData];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  setFinalFsData([...dataUpdate]);
                  resolve();
                }, 1000);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataDelete = [...finalFsData];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  setFinalFsData([...dataDelete]);

                  resolve();
                }, 1000);
              }),
          }}
        />
      </Row>
    </Row>
  );
}
