import React from 'react'
import { Redirect,Route } from 'react-router-dom';
import { useAuth } from './AuthContext';
export default function PrivateRouteAdmin({ component: Component, ...rest}) {

    const {currentUser} = useAuth();
    var checkadm=false;
    const listAdm=["reyhan@globalfenster.de","omer@globalwindow.pl","zafer@globalwindow.pl","omerzirh@gmail.com", "yuksel@globalwindow.pl", "ertugrul@globalwindow.pl","bahar@globalwindow.pl"];
    const checkAdmin=(email)=>{
      listAdm.forEach((eml)=>{
        if(eml===email){
          checkadm = true;
          return true;
        }
      })
      return checkadm;

    }
    return (
        <Route {...rest} render={props=>{
          return checkAdmin(currentUser.email)? <Component {...props}/>:<Redirect to="/login"/>
        }}>

        </Route>

    )
}
