export const inhandles=[
"Standard-Drücker_mit_Ovaler_Rosette_TYP-R32-1200.png",
"Drücker_mit_Langschild.png",
"Drücker_mit_Langschild_TYP_35Apng.png",
"Drücker_mit_Ovaler_Rosette.png",
"Knauf_mit_Langschild.png",
"Knauf_mit_ovaler_Rosette.png",
"Stossgriff_p+form_mit_Langscgild.png",
];

export const outhandles=[
    "Standard-SS45-2.png",
    "KW45-2.png",
    "KW90-2.png",
    "SS90-2.png",
    "WB45-2.png"
]