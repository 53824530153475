import React, { useState } from "react";
import { useAuth } from "../authentication/AuthContext";
import { useHistory, Link } from "react-router-dom";
import { Button } from "reactstrap";
export default function LogoutButton() {
  const [error, setError] = useState("");
  const { logout } = useAuth();
  const history = useHistory();
  async function handleLogout() {
    setError("");

    try {
      await logout();
      history.pushState("/login");
    } catch {
      setError("Failed to log out");
    }
  }
  return (
    <div>
      <br />
      <Link to="/login">
        <Button variant="contained" color="secondary" onClick={handleLogout}>
        Logout
        </Button>
      </Link>
    </div>
  );
}
