export const glasslines = [
  "GL-01_0.JPG",
  "GL-02_0.JPG",
  "GL-03_0.JPG",
  "GL-04_0.JPG",
  "GL-05_0.JPG",
  "GL-06_0.JPG",
  "GL-07_0.JPG",
  "GL-08_0.JPG",
  "GL-09_0.JPG",
  "GL-10_1.JPG",
  "GL-11_1.JPG",
  "GL-12_0.JPG",
  "GL-12_0.JPG",
  "GL-13_0.JPG",
  "GL-14_0.JPG",
  "GL-15_0.JPG",
  "GL-16_0.JPG",
  "GL-17_0.JPG",
  "GL-18_0.JPG",
  "GL-19_0.JPG",
  "GL-20_0.JPG",
  "GL-21_0.JPG",
  "GL-22_0.JPG",
  "GL-23.JPG",
  "GL-24.JPG",
  "GL-25.JPG",
  "GL-26.PNG",
  "GL-27.PNG",
  "GL-28.PNG",
  "GL-29.PNG",
  "GL-30.PNG",
  "GL-31.PNG",
];

export const ecolines = [
  "ek_1.png",
  "ek_13.png",
  "ek_16.png",
  "ek_17.png",
  "ek_2.png",
  "ek_22.png",
  "ek_2_piasek8.png",
  "ek_3.png",
  "ek_32.png",
  "ek_4.png",
  "ek_40.png",
  "ek_42.png",
  "ek_52a.png",
  "ek_53.png",
];

export const prestigelines = [
  "pl-00.png",
  "pl-01.png",
  "pl-02.png",
  "pl-03.png",
  "pl-04.png",
  "pl-05.png",
  "pl-06.png",
  "pl-07.png",
  "pl-08.png",
  "pl-09.png",
  "pl-10.png",
  "pl-11.png",
  "pl-12.png",
  "pl-13.png",
  "pl-14.png",
  "pl-15.png",
  "pl-16.png",
  "pl-17.png",
  "pl-18.png",
  "pl-19.png",
  "pl-20.png",
  "pl-21.png",
  "pl-22.png",
  "pl-23.png",
  "pl-24.png",
  "pl-25.png",
  "pl-26.png",
  "pl-27.png",
  "pl-28.png",
  "pl-29.png",
  "pl-30.png",
  "pl-31.png",
  "pl-32.png",
  "pl-33.png",
  "pl-34.png",
  "pl-35.png",
];
export const glassOnly = [
  "2-fach-Verglasung.png",
  "3-fach-Verglasung.png",
];
