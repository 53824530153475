import React, { useRef,useState} from 'react'
import { Link, useHistory} from 'react-router-dom';
import { Form,FormGroup,Label,Input,Button,Card,CardBody, CardTitle,Container, Alert } from 'reactstrap';
import { useAuth } from './AuthContext';
export default function UpdateProfile(){

    const [input, setInput] = useState('')
    const {currentUser, updatePassword, updateEmail, updateName,user} = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false)
    const history = useHistory();
    async function handleSubmit(e){
        e.preventDefault();

        if(input.password!== input.passwordConfirm){
            return setError('Passwords do not match');
        }
        const promises = []
        setLoading(true)
        setError("")
        if( input.email!=null&& input.email!==currentUser.email){
            promises.push(updateEmail(input.email));
        }
        if(input.password){
            promises.push(updatePassword(input.password))
        }
        if(input.name){
            promises.push(updateName(input.name))
        }
        Promise.all(promises).then(()=>{
            history.push('/update-profile')
        }).catch(()=>{setError('Failed to update account')
    }).finally(()=>{
        setLoading(false)
    })
      
    }
    return (
        <>
        <Card>
            <CardBody>
            <CardTitle tag="h5">Profil Aktualisieren</CardTitle>
            {error&& <Alert color="danger">{error}</Alert>   }
                <Form onSubmit={handleSubmit}>
                    <FormGroup id="email">
                        <Label >Email</Label >
                        <Input type="email" onChange={(event)=>
                                setInput({ ...input, email: event.target.value})

                        }placeholder="leer lassen, um gleich zu bleiben" />
                    </FormGroup>
                    <FormGroup id="name">
                        <Label >Vorname</Label >
                        <Input type="text" onChange={(event)=>
                                setInput({ ...input, name: event.target.value})

                        }placeholder="leer lassen, um gleich zu bleiben"   />
                    </FormGroup>
                    <FormGroup id="password">
                        <Label >Passwort wählen</Label >
                        <Input  type="password" onChange={(event)=>
                                setInput({ ...input, password: event.target.value})

                        }   placeholder="leer lassen, um gleich zu bleiben"/>
                    </FormGroup>
                    <FormGroup id="password-confirm">
                        <Label > Passwort wiederholen</Label >
                        <Input  type="password" onChange={(event)=>
                                setInput({ ...input, passwordConfirm: event.target.value})

                        }  placeholder="leer lassen, um gleich zu bleiben"/>
                    </FormGroup>
                    <Button disabled={loading} color="primary" type="submit">Aktualisieren</Button>
                </Form>
            </CardBody>
            <div className="w-100 text-center mt-2">
                <Link to="/"> Abbrechen</Link>
            </div>
        </Card>
        </>

    )
}