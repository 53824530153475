import React, { useContext, useState } from "react";
import { Button,Typography } from "@material-ui/core";
import { multiStepContextAlu } from "../../StepContext";
import { Container, Row, Col } from "reactstrap";
import "../styles/extra.scss";
import {  getFilenameFromUrl } from "../functions";
export default function Kstep() {
  const { setStep, userData, setUserData, urlData, setUrlData } =
    useContext(multiStepContextAlu);
  const [doorphone, setdoorphone] = useState(false);
  const [camera, setcamera] = useState(false);
  const [eoffner, setEoffner] = useState(false);
  const [ots, setOts] = useState(false);  
  var handleChangeCamera = () => {
    camera ? setcamera(false) : setcamera(true);
  };
  var handleChangeDoorphone = () => {
    doorphone ? setdoorphone(false) : setdoorphone(true);
  };
  var handleChangeOffner = () => {
    eoffner ? setEoffner(false) : setEoffner(true);
  };
  var handleChangeOts = () => {
    ots ? setOts(false) : setOts(true);
  };
  function importAll(r) {
    return r.keys().map(r);
  }
  const cameraImages = importAll(
    require.context(
      "../../../public/accessories/extra/camera",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  const doorphoneImages = importAll(
    require.context(
      "../../../public/accessories/extra/doorphone",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  const otsImages = importAll(
    require.context(
      "../../../public/accessories/extra/ots",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  const eoffnerImages = importAll(
    require.context(
      "../../../public/accessories/extra/eoffner",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
var counter=0;
  return (
    <Container maxWidth="lg">
            <Typography variant="h3" color="primary" >
              Extra-Zubehoer
              </Typography>  
      <Row>
        <Col sm={6} md={6}>
        <Typography variant="h5" color="secondary" >
              Kamera
              </Typography>  
          <div class="cc-selector-extra">
            {cameraImages.map((image) => {
              var camera = getFilenameFromUrl(image.default);
              
              return (
                <div className="column">
                  <input
                    id={image.default}
                    type="checkbox"
                    name="extra"
                    onChange={(e) => {
                      setUserData({ ...userData, camera: camera });
                      setUrlData({ ...urlData, cameraUrl: image.default });
                      handleChangeCamera();
                    }}
                  ></input>
                  <label
                    className="drinkcard-cc-extra"
                    htmlFor={image.default}
                    style={{ backgroundImage: `url(${image.default})` }}
                  ></label>
                </div>
              );
            }) }
            
          </div>
        </Col>
        <Col sm={6} md={6}>
        <Typography variant="h5" color="secondary" >
        Tür Hörer
              </Typography>           
               <div class="cc-selector-extra">
            {doorphoneImages.map((image) => {
              var doorphone = getFilenameFromUrl(image.default);

              return (
                <div className="column">
                  <input
                    id={image.default}
                    type="checkbox"
                    name="outhandle"
                    onChange={(e) => {
                      setUserData({ ...userData, doorphone: doorphone });
                      setUrlData({ ...urlData, doorphoneUrl: image.default });
                      handleChangeDoorphone();
                    }}
                  ></input>
                  <label
                    className="drinkcard-cc-extra"
                    htmlFor={image.default}
                    style={{ backgroundImage: `url(${image.default})` }}
                  ></label>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
      <Row>
            <Col sm={6} md={6}>
            <Typography variant="h5" color="secondary" >
        E-Offner
              </Typography>           
               <div class="cc-selector-extra">
            {eoffnerImages.map((image) => {
              var filename = getFilenameFromUrl(image.default);

              return (
                <div className="column">
                  <input
                    id={image.default}
                    type="checkbox"
                    name="outhandle"
                    onChange={(e) => {
                      setUserData({ ...userData, eoffner: filename });
                      setUrlData({ ...urlData, eoffnerUrl: image.default });
                      handleChangeOffner();
                    }}
                  ></input>
                  <label
                    className="drinkcard-cc-extra"
                    htmlFor={image.default}
                    style={{ backgroundImage: `url(${image.default})` }}
                  ></label>
                </div>
              );
            })}
          </div>
            </Col>
            <Col sm={6} md={6}>
            <Typography variant="h5" color="secondary" >
        OTS
              </Typography>           
               <div class="cc-selector-extra">
            {otsImages.map((image) => {
              var filename = getFilenameFromUrl(image.default);

              return (
                <div className="column">
                  <input
                    id={image.default}
                    type="checkbox"
                    name="outhandle"
                    onChange={(e) => {
                      setUserData({ ...userData, ots: filename });
                      setUrlData({ ...urlData, otsUrl: image.default });
                      handleChangeOts();
                    }}
                  ></input>
                  <label
                    className="drinkcard-cc-extra"
                    htmlFor={image.default}
                    style={{ backgroundImage: `url(${image.default})` }}
                  ></label>
                </div>
              );
            })}
          </div>
            </Col>
      </Row>

      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <Button
            className="buttonBack"
            margin="normal"
            onClick={() => setStep(9)}
            variant="contained"
            color="secondary"
          >
            {" "}
            Back{" "}
          </Button>
          <Button
            className="buttonNext"
            margin="normal"
            onClick={() => setStep(11)}
            variant="contained"
            color="primary"
          >
            {" "}
            Next{" "}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
