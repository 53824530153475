import React, { useContext, useState, useEffect } from "react";
import { Button, Typography } from "@material-ui/core";
import { multiStepContextAlu } from "../../StepContext";
import {
  Container,
  Row,
  Col,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { checkRequiredFields } from "../functions";
import "../styles/secondStep.css";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  optionRow: {
    marginBottom: 15,
  },
}));

export default function Cstep() {
  const { setStep, userData, setUserData } =
    useContext(multiStepContextAlu);


  const [error, seterror] = useState("");
  const [required, setrequired] = useState(false);
  const [finalWidth, setFinalWidth] = useState(0);
  const [finalHeight, setFinalHeight] = useState(0);
  var toCheckFields = [userData["dimension1"]];
  var activeTyp = "";
  useEffect(() => {
    const check = checkRequiredFields(toCheckFields);
    setrequired(check);
  });


  const handleNext = () => {
    setFinalDimensions();
    required ? setStep(3) : seterror(`Fields cannot be empty`);
  };


  const doorOption = userData["doorOption"];
  var fields = [];

  const selectedTyp = require("../typSections/" + doorOption + ".png");

  const assignFields = (amount, callback) => {
    fields.push(
      <InputGroup>
        <Input
          key={"Dimension " + 2}
          name={"Dimension " + 2}
          type="number"
          placeholder="a"
          value={userData["dimension1"] || ""}
          onChange={(e) => {
            setUserData({ ...userData, dimension1: e.target.value });
          }}
          required
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>mm</InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    );
    fields.push(
      <InputGroup>
        <Input
          label={"b"}
          key={"Dimension " + 2}
          name={"Dimension " + 2}
          type="number"

          placeholder="b"
          value={userData["dimension2"]}
          onChange={(e) => {
            setUserData({ ...userData, dimension2: e.target.value });
          }}
          required
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>mm</InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    );
    fields.push(
      <InputGroup>
        <Input
          label={"c"}
          key={"Dimension " + 3}
          type="number"

          name={"Dimension " + 3}
          placeholder="c"
          value={userData["dimension3"]}
          onChange={(e) =>
            setUserData({ ...userData, dimension3: e.target.value })
          }
          required
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>mm</InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    );
    fields.push(
      <InputGroup>
        <Input
          label={"d"}
          key={"Dimension " + 4}
          type="number"

          name={"Dimension " + 4}
          placeholder="d"
          value={userData["dimension4"]}
          onChange={(e) =>
            setUserData({ ...userData, dimension4: e.target.value })
          }
          required
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>mm</InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    );
    fields.push(
      <InputGroup>
        <Input
          label={"e"}
          key={"Dimension " + 5}
          type="number"

          name={"Dimension " + 5}
          placeholder="e"
          value={userData["dimension5"]}
          type="number"
          onChange={(e) =>
            setUserData({ ...userData, dimension5: e.target.value })
          }
          required
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>mm</InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    );
    callback(amount, setFields);
  };
  const decideFieldAmount = (amount, callback) => {
    var tempArray = [];
    for (let i = 0; i < amount; i++) {
      tempArray[i] = fields[i];
    }
    callback(tempArray);
  };

  const setFields = (array) => {
    fields = array;
  };

  var dimensions = {
    typ_0: {
      width: ["dimension2"],
      height: ["dimension1"],
    },
    typ_1: {
      width: ["dimension3"],
      height: ["dimension1", "dimension2"],
    },
    typ_2: {
      width: ["dimension2", "dimension3"],
      height: ["dimension1"],
    },
    typ_3: {
      width: ["dimension2", "dimension3"],
      height: ["dimension1"],
    },
    typ_4: {
      width: ["dimension3", "dimension4"],
      height: ["dimension1", "dimension2"],
    },
    typ_5: {
      width: ["dimension3"],
      height: ["dimension1", "dimension2"],
    },
    typ_6: {
      width: ["dimension2", "dimension3", "dimension4"],
      height: ["dimension1"],
    },
    typ_7: {
      width: ["dimension3", "dimension4", "dimension5"],
      height: ["dimension1", "dimension2"],
    },
  };

  const setFinalDimensions = () => {
    let height = 0;
    let width = 0;
    dimensions[activeTyp].height.map((element) => {
      if (userData[element] != null) {
        height += parseInt(userData[element]);
      } else {
        height = 0;
      }
    });
    dimensions[activeTyp].width.map((element) => {
      if (userData[element] != null) {
        width += parseInt(userData[element]);
      } else {
        width = 0;
      }
    });
    setFinalHeight(height);
    setFinalWidth(width);
  };
  switch (doorOption) {
    case "typ_0":
      assignFields(2, decideFieldAmount);
      activeTyp = "typ_0";
      toCheckFields = [userData["dimension1"], userData["dimension2"]];

      break;
    case "typ_1":
      assignFields(3, decideFieldAmount);
      activeTyp = "typ_1";
      toCheckFields = [
        userData["dimension1"],
        userData["dimension2"],
        userData["dimension3"],
      ];
      break;
    case "typ_2":
      assignFields(3, decideFieldAmount);
      activeTyp = "typ_2";
      toCheckFields = [
        userData["dimension1"],
        userData["dimension2"],
        userData["dimension3"],
      ];
      break;
    case "typ_3":
      assignFields(3, decideFieldAmount);
      activeTyp = "typ_3";
      toCheckFields = [
        userData["dimension1"],
        userData["dimension2"],
        userData["dimension3"],
      ];
      break;
    case "typ_4":
      assignFields(4, decideFieldAmount);
      activeTyp = "typ_4";
      toCheckFields = [
        userData["dimension1"],
        userData["dimension2"],
        userData["dimension3"],
        userData["dimension4"],
      ];
      break;
    case "typ_5":
      assignFields(4, decideFieldAmount);
      activeTyp = "typ_5";
      toCheckFields = [
        userData["dimension1"],
        userData["dimension2"],
        userData["dimension3"],
        userData["dimension4"],
      ];
      break;
    case "typ_6":
      assignFields(4, decideFieldAmount);
      activeTyp = "typ_6";
      toCheckFields = [
        userData["dimension1"],
        userData["dimension2"],
        userData["dimension3"],
        userData["dimension4"],
      ];
      break;
    case "typ_7":
      assignFields(5, decideFieldAmount);
      activeTyp = "typ_7";
      toCheckFields = [
        userData["dimension1"],
        userData["dimension2"],
        userData["dimension3"],
        userData["dimension4"],
        userData["dimension5"],
      ];
      break;
    default:
      break;
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" color="primary">
        Masse
      </Typography>
      <span className="requiredWarning">{error}</span>
      <div>
        <form>
          <Row>
            <Col>
              <img src={selectedTyp.default} width="200" height="250"></img>
            </Col>
            <Col>
              <Row>
                <Col>{fields}</Col>
                <Col>
                  <Button
                    className="buttonNext"
                    margin="normal"
                    onClick={setFinalDimensions}
                    variant="contained"
                    color="secondary"
                    aria-label="medium secondary button group"
                  >
                    Finale berechnen
                  </Button>
                  <p>Breite: {finalWidth}</p>

                  <p>Höhe: {finalHeight}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
        <div>
          <Button
            className="buttonBack"
            margin="normal"
            onClick={() => setStep(1)}
            variant="contained"
            color="Secondary"
          >
            {" "}
            Back{" "}
          </Button>
          <Button
            className="buttonNext"
            margin="normal"
            onClick={handleNext}
            variant="contained"
            color="primary"
          >
            {" "}
            Next{" "}
          </Button>
        </div>
      </div>
    </Container>
  );
}
