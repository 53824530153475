import React from 'react'
import './index.scss'
import { Card, CardBody, Alert, Container,Row,Col } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@mui/styles";
import Dashboard from './../authentication/Dashboard';

const Index = () => {
    
    return (
        <Container>
      <Row className="align-self-center" >
        <Col sm="12" lg="12" md="12">
          <Typography variant="h4">Admin Panel v1.0</Typography>
          <Card style={{ justifyContent: "center", marginBottom: 12 }}>
           
          </Card>
      
          <Card>
            <CardBody className="d-flex flex-column gap-3 ">
            <Link to="/allusers">
                <Button variant="contained" color="primary">
                  Orders
                </Button>
              </Link>
            <Link to="/completedorders">
                <Button variant="contained">
                  Completed Orders
                </Button>
              </Link>
              <Link to="/doororders">
                <Button variant="contained" color="secondary">
                Door Orders
                </Button>
              </Link>

              </CardBody>
              <CardBody>

             
            </CardBody>
          </Card>
         
     
          </Col>
      </Row>
    </Container>

    )
}

export default Index
