import React, { useRef,useState} from 'react'
import { Form,FormGroup,Label,Input,Button,Card,CardBody, CardTitle,Container, Alert,Row,Col} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from './AuthContext';
import logo from "../logo_gw.png";
export default function Login(){

    const [input, setInput] = useState('')
    const {signup,login} = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false)
    const history= useHistory();
    async function handleSubmit(e){
        e.preventDefault();

     
        try{
            setError('');
            setLoading(true)
            await login(input.email, input.password)
            history.push("/")            
        }catch(err){
            console.error(err);
            setError('Failed to sign in')
        }
        setLoading(false);
    }
    return (
        <>
        <Row style={{marginTop:"4rem", marginLeft:"-7rem",marginRight:'0.5rem'}}>
            <Col sm="12" md={{ size: 6, offset: 3 }}>
            <img src={logo} width="150" height="80" alt={logo} style={{}}></img>
            <CardTitle tag="h5">Dealer Automation System </CardTitle>

            </Col>
            <Col sm="12" md={{ size: 6, offset: 3 }}>
        <Card>
            <CardBody>
            <CardTitle tag="h5">Log In</CardTitle>
            {error&& <Alert color="danger">{error}</Alert>   }
                <Form onSubmit={handleSubmit}>
                    <FormGroup id="email">
                        <Label >Email</Label >
                        <Input type="email" onChange={(event)=>
                                setInput({ ...input, email: event.target.value})

                        }  required/>
                    </FormGroup>
                    <FormGroup id="password">
                        <Label >Passwort</Label >
                        <Input  type="password" onChange={(event)=>
                                setInput({ ...input, password: event.target.value})

                        }  required/>
                    </FormGroup>
         
                    <Button style={{marginTop:"10px"}} disabled={loading} color="primary" type="submit">Log In</Button>
                </Form>
                <div>
                    <Link to="/forgot-password">Passwort vergessen?</Link>
                </div>
            </CardBody>
         
        </Card>
        </Col>
        </Row>
        </>

    )
}