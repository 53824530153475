import React,{useContext} from 'react'
import {Button} from '@material-ui/core';
import { multiStepContextAlu } from "../../StepContext";
 import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.css';
import PDF from '../pdf';

export default function Mstep() {
    const {setStep,submitData,userData, urlData} = useContext(multiStepContextAlu);

  
 
     return (
      
     <Container maxWidth="md">
            <PDF userData={userData} urlData={urlData}/>
     
<div>
            <div>
        <Button className="buttonBack" margin="normal"  onClick={() => setStep(11)} variant="contained" color="Secondary"> Back </Button>
            <Button className="buttonNext"  margin="normal"  onClick={submitData} variant="contained" color="primary"> Submit </Button>

      
            </div>


        </div>
        </Container>
    )
}
