import React, { useContext, useState, useEffect } from "react";
import { Button, Typography } from "@material-ui/core";
import { multiStepContextAlu } from "../../StepContext";
import { Container, Row, Col } from "reactstrap";
import { checkRequiredFields } from "../functions";
import "../styles/RadioButtonStyle.css";
import { makeStyles } from "@material-ui/styles";
import inleftout from "../opening/2-out.png";
import inleftin from "../opening/2-in.png";
import inRightout from "../opening/1-out.png";
import inRightin from "../opening/1-in.png";

import outLeftout from "../opening/3-out.png";
import outLeftin from "../opening/3-in.png";
import outRightout from "../opening/4-out.png";
import outRightin from "../opening/4-in.png";

const useStyles = makeStyles((theme) => ({
  optionRow: {
    marginBottom: 15,
  },
}));

export default function Dstep() {
  const { setStep, userData, setUserData } = useContext(multiStepContextAlu);

  const inLeftStr = [
    "Ansicht von außen öffnet nach rechts",
    " Ansicht von innen öffnet nach links",
  ];
  const inRightStr = [
    "Ansicht von außen öffnet nach links",
    "Ansicht von innen öffnet nach rechts",
  ];
  const outLeftStr = [
    "Ansicht von außen öffnet nach links",
    "Ansicht von innen öffnet nach rechts",
  ];
  const outRightStr = [
    "Ansicht von außen öffnet nach rechts",
    "Ansicht von innen öffnet nach links",
  ];
  const [state, setState] = useState(false);
  const [error, seterror] = useState("");
  const [required, setrequired] = useState(false);
  useEffect(() => {
    const check = checkRequiredFields([
      userData["openingIn"],
      userData["openingOut"],
    ]);
    setrequired(check);
  });

  const classes = useStyles();

  const handleNext = () => {
    required ? setStep(4) : seterror(`Fields cannot be empty`);
  };
  var handleChange = () => {
    setState(!state);
  };
  return (
    <Container maxWidth="md">
      <Typography variant="h4" color="primary">
        Masse
      </Typography>
      <span className="requiredWarning">{error}</span>
      <div>
        <form>
          <Row>
            <Row>
              <h1>Din-Richtung</h1>
            </Row>
            <Row>
              <Col md={3}>
                <div>
                  <label className="container-radio">
                    öffnend von innen
                    <input
                      id="innenOpening"
                      type="radio"
                      value="Innen"
                      name="handling"
                      onChange={handleChange}
                      checked={!state}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="container-radio">
                    öffnend von außen
                    <input
                      id="ausenOpening"
                      type="radio"
                      value="Ausen"
                      name="handling"
                      onChange={handleChange}
                      checked={state}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </Col>
              <Col md={7}>
                <div className="cc-selector">
                  {state ? (
                    <div>
                      <Row style={{ width: "50%" }}>
                        <Col>
                          <Row className={classes.optionRow}>
                            <label className="container-radio">
                              <input
                                type="radio"
                                value="LeftIn"
                                name="opening"
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    openingIn: outLeftin,
                                    openingOut: outLeftout,
                                    openingStr: outLeftStr,
                                  })
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </Row>

                          <Row className={classes.optionRow}>
                            <img
                              src={outLeftout}
                              width="120"
                              height="200"
                              alt={outLeftout}
                            />
                          </Row>
                          <Row>
                            <p className="directionDef">{outLeftStr[0]}</p>
                          </Row>
                          <Row>
                            <img
                              src={outLeftin}
                              width="120"
                              height="200"
                              alt={outLeftin}
                            />
                          </Row>
                          <Row>
                            <p className="directionDef">{outLeftStr[1]}</p>
                          </Row>
                        </Col>
                        <Col>
                          <Row className={classes.optionRow}>
                            <label className="container-radio">
                              <input
                                type="radio"
                                value="LeftOut"
                                name="opening"
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    openingIn: outRightin,
                                    openingOut: outRightout,
                                    openingStr: outRightStr,
                                  })
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </Row>
                          <Row className={classes.optionRow}>
                            <img
                              src={outRightout}
                              width="120"
                              height="200"
                              alt={outRightout}
                            />
                          </Row>
                          <Row>
                            <p className="directionDef">{outRightStr[0]}</p>
                          </Row>
                          <Row>
                            <img
                              src={outRightin}
                              width="120"
                              height="200"
                              alt={outRightin}
                            />
                          </Row>
                          <Row>
                            <p className="directionDef">{outRightStr[1]}</p>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div>
                      <Row style={{ width: "50%" }}>
                        <Col>
                          <Row className={classes.optionRow}>
                            <label className="container-radio">
                              <input
                                type="radio"
                                value="LeftIn"
                                name="opening"
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    openingIn: inleftin,
                                    openingOut: inleftout,
                                    openingStr: inLeftStr,
                                  })
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </Row>

                          <Row className={classes.optionRow}>
                            <img
                              src={inleftout}
                              width="120"
                              height="200"
                              alt={inleftout}
                            />
                          </Row>
                          <Row>
                            <p className="directionDef">{inLeftStr[0]}</p>
                          </Row>
                          <Row>
                            <img
                              src={inleftin}
                              width="120"
                              height="200"
                              alt={inleftin}
                            />
                          </Row>
                          <Row>
                            <p className="directionDef">{inLeftStr[1]}</p>
                          </Row>
                        </Col>
                        <Col>
                          <Row className={classes.optionRow}>
                            <label className="container-radio">
                              <input
                                type="radio"
                                value="LeftOut"
                                name="opening"
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    openingIn: inRightin,
                                    openingOut: inRightout,
                                    openingStr: inRightStr,
                                  })
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </Row>
                          <Row className={classes.optionRow}>
                            <img
                              src={inRightout}
                              width="120"
                              height="200"
                              alt={inRightout}
                            />
                          </Row>
                          <Row>
                            <p className="directionDef">{inRightStr[0]}</p>
                          </Row>
                          <Row>
                            <img
                              src={inRightin}
                              width="120"
                              height="200"
                              alt={inRightin}
                            />
                          </Row>
                          <Row>
                            <p className="directionDef">{inRightStr[1]}</p>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              </Col>
              {required ? (
                <Col md={2}>
                  <h4 style={{ color: "#444" }}>Ausgewählt</h4>
                  <Row className={classes.optionRow}>
                    <Col>
                      <img
                        src={userData["openingOut"]}
                        width="60"
                        height="100"
                        alt={userData["openingOut"]}
                      />
                      <p className="directionDef">
                        {userData["openingStr"][0]}
                      </p>
                    </Col>
                    <Col>
                      <img
                        src={userData["openingIn"]}
                        width="60"
                        height="100"
                        alt={userData["openingIn"]}
                      />
                      <p className="directionDef">
                        {userData["openingStr"][1]}
                      </p>
                    </Col>
                  </Row>
                </Col>
              ) : null}
            </Row>
          </Row>
        </form>
        <div>
          <Button
            className="buttonBack"
            margin="normal"
            onClick={() => setStep(2)}
            variant="contained"
            color="Secondary"
          >
            {" "}
            Back{" "}
          </Button>
          <Button
            className="buttonNext"
            margin="normal"
            onClick={handleNext}
            variant="contained"
            color="primary"
          >
            {" "}
            Next{" "}
          </Button>
        </div>
      </div>
    </Container>
  );
}
