import React, {useContext, useEffect,useState} from 'react'
import { auth } from '../firebase';
import { multiStepContextAlu } from '../StepContext';
const AuthContext = React.createContext();

export function useAuth(){
    return useContext(AuthContext);
}

export function AuthProvider({children}){
    const [currentUser, setCurrentUser] = useState('user')
    const [loading, setLoading] = useState(true)
    const user = auth.currentUser;
    const {
        setUserData,
        setMailerState,
        setFinalData
      } = useContext(multiStepContextAlu);

    function login(email, password){
        return auth.signInWithEmailAndPassword(email, password)
    }
    function signup(email, password){
        return auth.createUserWithEmailAndPassword(email, password)
    }
    function logout(){
        setUserData('');
        setMailerState('');
        setFinalData('');
        setCurrentUser(null);
        return auth.signOut();
       
    }
    function resetPassword(email){
        return auth.sendPasswordResetEmail(email);
    }
    function updateEmail(email){
        currentUser.updateEmail(email);
    
    }
    function updatePassword(password){
        currentUser.updatePassword(password)
    }
    function updateName(name){
        if (user !== null) {

        user.updateProfile({
            displayName: name,
          }).then(() => {
            alert("Name successfully updated")
          }).catch((error) => {
            // An error occurred
            // ...
          });
        }
    }
    useEffect(() => {
       const unsubscribe = auth.onAuthStateChanged(user=> {
        setCurrentUser(user)

           setLoading(false)
        })

        return unsubscribe
    }, [])
 

    const value = {
        currentUser,
        login,
        signup,
        logout,
        resetPassword,
        updateEmail,
        updatePassword,
        updateName,
        user
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading &&  children}
        </AuthContext.Provider>
    )
}