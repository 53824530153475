import React, { useContext, useState } from "react";
import { db } from "../firebase";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Divider } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import {
  Row,
  Col,
  Card,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { multiStepContextAlu } from "./../StepContext";
import MaterialTableN from "./MaterialTableN";
import SendIcon from "@material-ui/icons/Send";
import AddIcon from "@material-ui/icons/AddCircle";
import CheckIcon from "@material-ui/icons/CheckCircle";
import { Link } from "react-router-dom";
import { useAuth } from "../authentication/AuthContext";
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  buttonSend: {
    marginTop: "10px",
    marginLeft: "8px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  divider: {
    border: "5px",
    color: "#333",
    height: 4,
  },
}));
const ref = React.createRef();

export default function MainPageFs() {
  const [doppel, setdoppel] = useState(false);
  const [approve, setApprove] = useState(false)
  const {user } = useAuth();



  const doppels = [
    {
      value: "Normal Doppel",
      text: "Normal Doppel",
    },
    {
      value: "Slim Doppel",
      text: "Slim Doppel",
    },
    {
      value: "Flachswelle Doppel",
      text: "Flachswelle Doppel",
    },
    {
      value: "Spannrahmen",
      text: "Spannrahmen",
    },
  ];
  const colors = [
    {
      value: "antrasit",
      text: "Antrasit",
    },
    {
      value: "white",
      text: "Beyaz",
    },
    {
      value: "darkbrown",
      text: "Koyu Kahve",
    },
  ];
  const normals = [
    {
      value: "Normal",
      text: "Fliegengitter",
    },
    {
      value: "Normal Doppel",
      text: "Fliegengitter Doppel",
    },
    {
      value: "Slim",
      text: "Slim Series Fliegengitter",
    },
    {
      value: "Slim Doppel",
      text: "Slim Series Fliegengitter Doppel",
    },
    {
      value: "Flachswelle",
      text: "Flachswelle",
    },
    {
      value: "Flachswelle Doppel",
      text: "Flachswelle Doppel",
    },
    {
      value: "Spannrahmen",
      text: "Spannrahmen",
    },
  ];
  const {
    orderFsData,
    setOrderFsData,
    userFsData,
    setUserFsData,
    submitFsData,
    addToFinalFs,
    finalFsData,
    setMailerFsState,
    setFsOfr,
  } = useContext(multiStepContextAlu);
  const [index, setIndex] = useState(0);
  const classes = useStyles();

  const handleChange = (event) => {
    setOrderFsData({
      ...orderFsData,
      type: event.target.value,
      id: "PLS" + index,
      index: index,
    });
  };

  const handleColor = (event) => {
    setOrderFsData({ ...orderFsData, color: event.target.value });
  };

  const [disabled, setdisabled] = useState(false);
  function assignOfr(){
    let docRef = db.collection("ofrcollection").doc("ofrassign");
    docRef.get().then((doc) => {
      if (doc.exists) {
        setFsOfr(parseInt(doc.data().ofr));
      } 
  }).catch((error) => {
  });
  }
  function handleAddClick() {
    addToFinalFs();
    setdisabled(true);
    setIndex(index + 1);
    assignOfr();
  }

  function handleSubmitClick() {
    submitFsData();
    setdisabled(false);
    setApprove(false);
  }
  function checkDoppelity(width) {
    if (width > 1600) {
      setdoppel(true);
    } else {
      setdoppel(false);
    }
  }
  function approveMove() {
    setMailerFsState({
      customer: userFsData,
      order: finalFsData,
    });
  }
  return (
    <>
      {" "}
      <h1>Global Window Fliegengitter</h1>
      <div style={{marginLeft:"125px"}}>
      <div style={{ display: "flex" }}>
        <Card body>
          <div>
            <p style={{ textAlign: "left" }}>
              <strong>Name:</strong>
              {user.displayName}
            </p>
            <p style={{ textAlign: "left" }}>
              <strong>Email:</strong>
              {user.email}
            </p>
            {user.email === "busra@globalwindow.pl" ? (
              <div>
                <TextField
                  label="Kunde "
                  name="customerName"
                  value={userFsData["customerName"] || ""}
                  onChange={(e) =>
                    setUserFsData({ ...userFsData, customerName: e.target.value })
                  }
                  margin="normal"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  disabled={disabled}
                />
                <TextField
                  label="Email "
                  name="email"
                  value={userFsData["email"] || ""}
                  onChange={(e) =>
                    setUserFsData({ ...userFsData, email: e.target.value })
                  }
                  margin="normal"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  disabled={disabled}
                />
                <TextField
                  label="Kommission"
                  name="commisionName"
                  value={userFsData["commisionName"] || ""}
                  onChange={(e) => {
                    setUserFsData({ ...userFsData, commisionName: e.target.value });
                  }}
                  margin="normal"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  disabled={disabled}
                />
              </div>
            ) : (
              <>
                 <Input
                  type="text"
                  name="commisionName"
                  value={userFsData["commisionName"] || ""}
                  onChange={(e) =>
                    setUserFsData({
                      ...userFsData,
                      commisionName: e.target.value,
                      customerName: user.displayName,
                      email: user.email,
                    })
                  }
                  placeholder="Kommission"
                  
                  required
                />
             
              </>
            )}
            {/* */}
          </div>
        </Card>
        <Card body style={{ marginLeft: "2rem" }}>
          <div>
            <Row>
              <Col>
                <InputGroup>
                  <Input
                    name="width"
                    type="number"
                    placeholder="Breite"
                    value={orderFsData["width"] || ""}
                    onChange={(e) => {
                      setOrderFsData({ ...orderFsData, width: e.target.value });
                      checkDoppelity(e.target.value);
                    }}
                    required
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>mm</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col>
                <InputGroup>
                  <Input
                    name="height"
                    type="number"
                    placeholder="Höhe"
                    value={orderFsData["height"] || ""}
                    onChange={(e) => {
                      setOrderFsData({ ...orderFsData, height: e.target.value });
                    }}
                    required
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>mm</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
            <Row style={{marginLeft:"4px", marginRight:"1px"}}>
              <FormControl>
                <InputLabel htmlFor="typeSelector">Typ</InputLabel>
                <NativeSelect
                  id="typeSelector"
                  value={orderFsData["type"] || ""}
                  onChange={handleChange}
                >
                  <option aria-label="None" value="" />

                  {doppel
                    ? doppels.map((element) => {
                        return (
                          <option value={element.value}>{element.text}</option>
                        );
                      })
                    : normals.map((element) => {
                        return (
                          <option value={element.value}>{element.text}</option>
                        );
                      })}
                </NativeSelect>
              </FormControl>
            </Row>

            <Row style={{marginTop:"2rem"}}>
              <Col lg="3">
                <Input
                  type="number"
                  value={orderFsData["amount"] || ""}
                  onChange={(e) =>
                    setOrderFsData({ ...orderFsData, amount: e.target.value })
                  }
                  placeholder="Anzahl"
                  required
                />
              </Col>
              <Col lg="9">
                <FormControl>
                  <InputLabel htmlFor="colorSelector">Farbe</InputLabel>
                  <NativeSelect
                    id="colorSelector"
                    value={orderFsData["color"] || ""}
                    onChange={handleColor}
                  >
                    <option aria-label="None" value="" />

                    {colors.map((element) => {
                      return (
                        <option value={element.value}>{element.text}</option>
                      );
                    })}
                  </NativeSelect>
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col lg={{ size: "auto", offset: 8 }}>
                <Button
                  margin="normal"
                  
                  onClick={() => {
                    handleAddClick();
                  }}
                  variant="contained"
                  color="secondary"
                  startIcon={<AddIcon>send</AddIcon>}
                  style={{marginTop:"2rem"}}
                >
                  Hinzufügen
                </Button>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
      <Divider className={classes.divider} />
      <Row>
        <Col>
          <div className="post" ref={ref}>
            {finalFsData !== "" ? <MaterialTableN /> : <></>}
          </div>

          <Button
            margin="normal"
            onClick={()=>{approveMove(); setApprove(!approve);  }}
            variant="contained"
            color={approve?"primary":"secondary"}
            className={classes.buttonSend}
            endIcon={<CheckIcon></CheckIcon>}
          >
            {approve?"Abnehmen":"Akzeptieren "}
          </Button>

          <Button
            margin="normal"
            onClick={handleSubmitClick}
            variant="contained"
            color="secondary"
            disabled={!approve}
            className={classes.buttonSend}
            endIcon={<SendIcon>send</SendIcon>}>
            Senden
          </Button>
         
        </Col>
      </Row>
      </div>
    </>
  );
}
