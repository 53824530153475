import React from "react";

const checkRequiredFields = (event) => {
  var result = false;

  for (var elm of event) {
    if (elm == "" || elm == null) {
      result = false;
      break;
    } else if (elm != "" && elm != null) {
      result = true;
    }
  }
  return result;
};

const getFilenameFromUrl = (url) => {
  var splitted = url.split("/");
  splitted[3].indexOf(".");
  var filename = splitted[3]
    .substr(0, splitted[3].indexOf("."))
    .replace(/-|_/g, " ");

  return filename;
};
const getOptionFilenameFromUrl = (url) => {
  var splitted = url.split("/");
  splitted[3].indexOf(".");
  var filename = splitted[3]
    .substr(0, splitted[3].indexOf("."));

  return filename;
};



export { getFilenameFromUrl,getOptionFilenameFromUrl, checkRequiredFields };
