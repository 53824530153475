import React, { useContext, useState, useEffect, useRef } from "react";
import { Button, FormControl, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { multiStepContextAlu } from "../../StepContext";
import { Container, Row, Col } from "reactstrap";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import "../styles/accessories.scss";
import { checkRequiredFields, getFilenameFromUrl } from "../functions";
import { inhandles, outhandles } from "../StepFiles/SeventhStepFiles";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

export default function Istep() {
  const classes = useStyles();
  const [outhandleSize, setouthandleSize] = useState();
  const [refresh, setRefresh] = useState(1);
  const [sizeArray, setSizeArray] = useState([
    "500mm",
    "600mm",
    "800mm",
    "1000mm",
    "1200mm",
    "1400mm",
    "1600mm",
    "1800mm",
  ]);
  const sizeRef = useRef(null);
  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setUserData({ ...userData, outhandleSize: value[0] });

    setouthandleSize(value);
  };
  const { setStep, userData, setUserData, urlData, setUrlData } =
    useContext(multiStepContextAlu);


  const [error, seterror] = useState("");
  const [required, setrequired] = useState(false);


  useEffect(() => {
    const check = checkRequiredFields([
      userData["inhandle"],
      userData["outhandle"],
      userData["outhandleSize"],
    ]);
    setrequired(check);
    setRefresh(0);
  }, [userData]);
  useEffect(() => {
    if (refresh === 0) {
      setRefresh(1);
    }
  }, [refresh]);

  const handleNext = () => {
    required ? setStep(9) : seterror(`Fields cannot be empty`);
  };

  function importImages(array, name) {
    var imageArray = [];
    array.map((element) => {
      imageArray.push([
        element,
        require("../accessories/" + name + "/" + element),
      ]);
    });
    return imageArray;
  }
 
  const OuthandleSizeComp = (props) => {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel shrink htmlFor="select-multiple-native">
          Aussengriffe Grösse
        </InputLabel>
        <Select
          multiple
          native
          value={outhandleSize}
          onChange={handleChangeMultiple}
          inputProps={{
            id: "select-multiple-native",
          }}
          ref={sizeRef}
        >
          {props.val.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </Select>
      </FormControl>
    );
  };
  const handleOuthandleForSize = (e) => {
    let selectedOuthandle = e.target.value;
    if (selectedOuthandle === "Standard SS45 2") {
      setSizeArray([
        "500mm",
        "600mm (Standard)",
        "800mm",
        "1000mm",
        "1200mm",
        "1400mm",
        "1600mm",
        "1800mm",
      ]);
      setRefresh(0);
    } else {
      setSizeArray([
        "500mm",
        "600mm",
        "800mm",
        "1000mm",
        "1200mm",
        "1400mm",
        "1600mm",
        "1800mm",
      ]);
    }
  };
  const newouthandleImages = importImages(outhandles, "outhandle");
  const newinhandleImages = importImages(inhandles, "inhandle");

  return (
    <Container maxWidth="lg">
      <Typography variant="h3" color="primary">
        Zubehoer
      </Typography>
      <span className="requiredWarning">{error}</span>

      <Row>
        <Col lg={4} md={4}>
          <Typography variant="h5" color="secondary">
            Innendruecker
          </Typography>
          <div class="cc-selector-accessories">
            {newinhandleImages.map((image) => {
              var filename = getFilenameFromUrl(image[1].default);
              return (
                <div className="column">
                  <input
                    id={image[1].default}
                    type="radio"
                    name="inhandle"
                    onChange={(e) => {
                      setUserData({ ...userData, inhandle: filename });
                      setUrlData({ ...urlData, inhandleUrl: image[1].default });
                    }}
                  ></input>
                  <label
                    className="drinkcard-cc-accessory inhandle"
                    htmlFor={image[1].default}
                    style={{ backgroundImage: `url(${image[1].default})` }}
                  ></label>
                  <p>{filename}</p>
                </div>
              );
            })}
          </div>
        </Col>
        <Col lg={6} md={6}>
          <Typography variant="h5" color="secondary">
            Aussengriffe
          </Typography>
          <div class="cc-selector-accessories">
            {newouthandleImages.map((image) => {
              var filename = getFilenameFromUrl(image[1].default);

              return (
                <Row>
                  <div className="columnAccessories">
                    <input
                      id={image[1].default}
                      type="radio"
                      name="outhandle"
                      value={filename}
                      onChange={(e) => {
                        setUserData({ ...userData, outhandle: filename });
                        setUrlData({
                          ...urlData,
                          outhandleUrl: image[1].default,
                        });
                        handleOuthandleForSize(e);
                      }}
                    ></input>
                    <label
                      className="drinkcard-cc-accessory outhandle"
                      htmlFor={image[1].default}
                      style={{ backgroundImage: `url(${image[1].default})` }}
                    ></label>
                    <p>{filename}</p>
                  </div>
                </Row>
              );
            })}
          </div>
        </Col>
        <Col>
          <Row>{refresh ? <OuthandleSizeComp val={sizeArray} /> : null}</Row>
          <Row style={{ marginTop: "3rem" }}>
            {required ? (
              <div>
                <h6 style={{ color: "#444" }}>Ausgewählt</h6>
                <img
                  src={urlData["inhandleUrl"]}
                  width="80"
                  height="120"
                  alt={userData["inhandle"]}
                />{" "}
                <p>{userData["inhandle"]}</p>{" "}
                <img
                  src={urlData["outhandleUrl"]}
                  width="150"
                  height="150"
                  alt={userData["outhandle"]}
                ></img>
                <p>
                  {userData["outhandle"]},&nbsp;
                  {userData["outhandleSize"]}
                </p>
              </div>
            ) : null}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <Button
            className="buttonBack"
            margin="normal"
            onClick={() => setStep(7)}
            variant="contained"
            color="secondary"
          >
            {" "}
            Back{" "}
          </Button>
          <Button
            className="buttonNext"
            margin="normal"
            onClick={handleNext}
            variant="contained"
            color="primary"
          >
            {" "}
            Next{" "}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
