import React from 'react'
import Orders from './Orders'
import { useAuth } from '../authentication/AuthContext';
function AludoorOrders() {

    const { currentUser } = useAuth();
  return <Orders user={currentUser} />;
}

export default AludoorOrders;
