import React, { useContext, useState, useEffect } from "react";
import { Button, Typography} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { checkRequiredFields } from "../functions";
import '../styles/Option.css'
import { multiStepContextAlu } from "../../StepContext";
import { getOptionFilenameFromUrl } from "../functions";
import Alert from '@material-ui/lab/Alert';

export default function Bstep() {
  const [error, seterror] = useState("");
  const [state, setstate] = useState(false);

  const { currentStep,setStep, userData, setUserData, urlData, setUrlData } =
    useContext(multiStepContextAlu);
  useEffect(() => {
    const check = checkRequiredFields([userData["doorOption"]]);
    setstate(check);
  });
  const handleNext = () => {
    state ? setStep(currentStep+1) : seterror(`Fields cannot be empty`);

  };

  function importAll(r) {
    return r.keys().map(r);
  }
  const optionImages = importAll(
    require.context("../../../public/images/options", false, /\.(png|jpe?g|svg)$/)
  );


  return (
    <Container maxWidth="lg">
  <div style={{maxWidth:"20%"}}>
          <Alert severity="error">
          Ansicht von außen
          </Alert>
        </div>
      <Typography variant="h4" color="primary" >
              Typ
              </Typography> 
      <span className="requiredWarning">{error}</span>

      <div>


        <div id="photos" className="cc-selector-option">
          
   
          {optionImages.map((image) => {
            const filename= getOptionFilenameFromUrl(image.default)
            return (
              <div className="column">
                <input
                  id={image.default}
                  type="radio"
                  name="doorOption"
                  onChange={(e) => {
                    setUserData({ ...userData, doorOption: filename,
                      doorOptionWithLetters: require("../typSections/" + filename + ".png").default,
                      doorOptionGlass:   require("../glazingSections/" + filename + ".png").default });
                    setUrlData({ ...urlData, doorOptionUrl: image.default});
                

                  }}
                ></input>
                <label
                  className="drinkcard-cc-option"
                  htmlFor={image.default}
                  style={{ backgroundImage: `url(${image.default})` }}
                ></label>
              </div>
            );
          })}
        </div>
  {urlData["doorOptionUrl"]!=null?
<div>
  <h4 style={{color:"#444"}}>Selected Typ</h4>
  <img width="50px" height="75px" src={urlData["doorOptionUrl"]} alt={userData["doorOption"]}/>
</div>:null}

          <Button
            className="buttonBack"
            margin="normal"
            onClick={() => setStep(currentStep-1)}
            variant="contained"
            color="Secondary"
          >
            {" "}
            Back{" "}
          </Button>

          <Button
            className="buttonNext"
            margin="normal"
            onClick={handleNext}
            variant="contained"
            color="primary"
          >
            {" "}
            Next{" "}
          </Button>
      </div>
    </Container>
  );
}
