import React from "react";

import { GoogleSpreadsheet } from "google-spreadsheet";

import MaterialTable from "material-table";
import { Typography } from "@material-ui/core";

export default class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.classes = "";

    this.state = { filtered: [] };
  }

  appendSpreadsheet = async (callback) => {
    try {
      const response = await fetch(
        "https://sheetsgw.herokuapp.com/production/rows/",
        {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({ customerName: this.props.name }),
        }
      ).then((res) => {
        res.json().then((elm) => {
          this.setState({ filtered: elm });
        });
      });
    } catch (e) {
      console.error("Error: ", e);
    }
  };
  componentDidMount() {
    this.appendSpreadsheet();
  }

  render() {
    return (
      <div style={{ width: "100%",marginLeft:"120px" }}>
        <Typography variant="h4" style={{ marginBottom: "2%" }}>
          {" "}
          {this.props.name} Produktionsverfolgung
        </Typography>
        <MaterialTable
          title=""
          columns={[
            {
              title: "Date",
              field: "date",
            },
            {
              title: "Ofr",
              field: "ofr",
            },
            {
              title: "Name",
              field: "commision",
            },
            {
              title: "Profil",
              field: "profil",
            },
            {
              title: "Fenster",
              field: "fenster",
            },
            {
              title: "Aufsatz Rollladen",
              field: "aufsatz",
            },
            {
              title: "Varbou",
              field: "varbou",
            },
            {
              title: "Raffstore",
              field: "raffstore",
            },
            {
              title: "HSI",
              field: "hsi",
            },
            {
              title: "PAS/PSK",
              field: "pas_psk",
            },
            {
              title: "Alutüren",
              field: "aludoor",
            },
            {
              title: "Türen",
              field: "door",
            }
          ]}
          data={this.state.filtered}
          options={{ pageSize: 10 }}
        />
      </div>
    );
  }
}
