import React from "react";

import { GoogleSpreadsheet } from "google-spreadsheet";

import MaterialTable from "material-table";
import { Typography, TextField} from "@material-ui/core";

import Autocomplete from '@mui/material/Autocomplete';
function createData(date, ofr, commision, profil, fenster, aufsatz, varbou,price) {
  return { date, ofr, commision, profil, fenster, aufsatz, varbou,price };
}

export default class Orders extends React.Component {
  constructor(props) {
    super(props);
    // Initializing the state

    this.classes = "";

  this.state = {  users: [],value:"",inputValue:"", filtered:[]}
  }


  getUsers = async (callback) => {
    try {
        const response = await fetch("https://gwadminapi.herokuapp.com/getusers", {
           mode: "cors",
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
        })
          .then((res) => {
            res.json().then(elm=>{
              this.setState({users:elm})
            })
          })
    } catch (e) {
      
      console.error("Error: ", e);
    }
  };
  componentDidMount() {
    this.getUsers();
  }

  appendSpreadsheet = async (cusName) => {
    try {
        const response = await fetch("https://sheetsgw.herokuapp.com/production/rows/", {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
         body: JSON.stringify({ "customerName":cusName}),
        })
          .then((res) => {

            res.json().then(elm=>{
              this.setState({filtered:elm})
            })
          })
    } catch (e) {
      
      console.error("Error: ", e);
    }
  };

  render() {
    return (
      <div style={{ width: "100%" }}>
        <div>
        <Typography variant="h4" style={{marginBottom:"2%"}}> {this.props.name}{this.state.value} Produktionsverfolgung </Typography>
        <Autocomplete
  disablePortal
  
  id="combo-box-demo"
  options={this.state.users}
  sx={{ width: 300 }}
  value={this.state.value}
  onChange={(event, newValue) => {
      this.appendSpreadsheet(newValue);
    this.setState({value:newValue});
  }}
  inputValue={this.setState.inputValue}
  onInputChange={(event, newInputValue) => {
    this.setState({inputValue:newInputValue});
  }}
  renderInput={(params) => <TextField {...params} label="Klient" />
  }
/>
</div>
<div>
<MaterialTable
          title=""
          columns={[
            {
              title: "Date",
              field: "date",
            },
            {
              title: "Ofr",
              field: "ofr",
            },
            {
              title: "Name",
              field: "commision",
            },
            {
              title: "Profil",
              field: "profil",
            },
            {
              title: "Fenster",
              field: "fenster",
            },
            {
              title: "Aufsatz Rollladen",
              field: "aufsatz",
            },
            {
              title: "Varbou",
              field: "varbou",
            },
          
            {
              title: "Raffstore",
              field: "raffstore",
            },
            {
              title: "HSI",
              field: "hsi",
            },
            {
              title: "PAS/PSK",
              field: "pas_psk",
            },
            {
              title: "Alutüren",
              field: "aludoor",
            },
            {
              title: "Türen",
              field: "door",
            },
            {
              title: "price",
              field: "price",
            }
          ]}
          data={this.state.filtered}
          options={{ pageSize: 10 }}
        />
</div>


      </div>
    );
  }
}
