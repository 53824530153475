import React,{useContext} from "react";
import { Row, Container } from "reactstrap";
import { Typography } from "@material-ui/core";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowImage from "./assets/arrow.png";
import "./main.scss";
import { useAuth } from "./authentication/AuthContext";
import {multiStepContextAlu} from "./StepContext";
export default function Mainpage() {
  const {setUserCurrent} =useContext(multiStepContextAlu);
  const {currentUser} =useAuth();
  setUserCurrent(currentUser);
  return (
    <Container spacing={2}>
      <img
        style={{
          width: 123,
          height: 105,
          transform: "rotate(155deg)",
          float: "left",
          marginLeft: "-15rem",
          marginRight: "15rem",
          zIndex: "-1",
        }}
        alt="arrow"
        src={ArrowImage}
        className="arrowImg"
      />

      <Row>
        <Typography variant="h3" component="div" gutterBottom>
          <div style={{ color: "#008ccb" }}>Global Window</div> Dealer
          Automation System
        </Typography>
      </Row>
      <Row className="mainRow">
        <List>
          <ListItem>
            <ListItemIcon>
              <ArrowForwardIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="DAS provides you easy to use interface for giving and managing orders in Global Window" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ArrowForwardIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="You can check your orders from Orders Page" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ArrowForwardIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="You can give aluminium door order from Aludoor Konfigurator" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ArrowForwardIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="You can give flyscreen order from Flyscreen Konfigurator" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ArrowForwardIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="You can change details about your account from Dashboard page" />
          </ListItem>
        </List>
      </Row>
      <Row></Row>
    </Container>
  );
}
