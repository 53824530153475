
import firebase from 'firebase/app'
import 'firebase/database'; // If using Firebase database
import 'firebase/storage'; 
import 'firebase/firestore'; 
import 'firebase/auth'; 
const firebaseApp = firebase.initializeApp(

    {// For Firebase JS SDK v7.20.0 and later, measurementId is optional
    apiKey: "AIzaSyBsm5CtOFQF-UgYeM3a-GYTQc7FzCGhWGY",
    authDomain: "konfigurator-gw.firebaseapp.com",
    projectId: "konfigurator-gw",
    storageBucket: "konfigurator-gw.appspot.com",
    messagingSenderId: "430699574557",
    appId: "1:430699574557:web:0f3624a92ab041e73a1df4",
    measurementId: "G-QZBK7C1NRF"
  
    }
);

const db= firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage}