import React, { useState} from "react";
import { Card, CardBody, Alert, Container,Row,Col } from "reactstrap";
import { useAuth } from "./AuthContext";
import { Link, useHistory } from "react-router-dom";
import { auth } from "../firebase";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@mui/styles";


const useStyles= makeStyles((theme)=>({
  rows:{
    marginBottom:"15px"
  }
}))
export default function Dashboard() {
  const [error, setError] = useState("");
  const { currentUser, logout, user } = useAuth();
  const history = useHistory();

  async function handleLogout() {
    try {
      await logout();
      history.push("/dashboard");
    } catch (error) {
      console.error(error);
      setError("Failed to log out");
    }
  }
  const classes = useStyles();
  return (
    <Container>
      <Row className="align-self-center" >
        <Col sm="12" lg="12" md="12">
          <Typography variant="h4">Profil</Typography>
          <Card style={{ justifyContent: "center", marginBottom: 12 }}>
            <CardBody>
              {error && <Alert color="danger">{error}</Alert>}
              <strong>Vorname: </strong> {currentUser.displayName}
              <br />
              <strong>Email: </strong> {currentUser.email}
              <br />
            </CardBody>
          </Card>
          <Card className={classes.rows}>
            <CardBody>
              <Row className={classes.rows}>
              <Link to="/update-profile">
                <Button variant="contained" color="secondary">
                  Profil Aktualisieren
                </Button>
              </Link>

              </Row>
              <Row  className={classes.rows}>
              {user.email === "omer@globalwindow.pl" ||
              "busra@globalwindow.pl" ? (
                <Link to="/signup">
                  <Button variant="contained" color="">
                    Sign up a user
                  </Button>
                </Link>
              ) : (
                <></>
              )}
              </Row>
            
                  <Row  className={classes.rows}>

                

                  </Row>
         

            </CardBody>
          </Card>
          <Card>
            <CardBody>
            <Link to="/">
                <Button variant="contained" color="primary">
                  Zurück zur Hauptseite
                </Button>
              </Link>
            </CardBody>
          </Card>
         
     
          </Col>
      </Row>
    </Container>
  );
}
