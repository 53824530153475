import React, { useContext, useState, useEffect } from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import { multiStepContextAlu } from "../../StepContext";
import { Container, Row, Col } from "reactstrap";
import "../styles/glazing.css";
import { checkRequiredFields, getFilenameFromUrl } from "../functions";

export default function Gstep() {
  const { setStep, userData, setUserData, urlData, setUrlData } =
    useContext(multiStepContextAlu);
  const [error, seterror] = useState("");
  const [required, setrequired] = useState(false);
  var toCheckFields = [];

  useEffect(() => {
    const check = checkRequiredFields(toCheckFields);
    setrequired(check);
  });
  const handleNext = () => {
    required ? setStep(7) : seterror(`Fields cannot be empty`);
    if (document.getElementById("glazingNote").innerHTML === "") {
      setUserData({ ...userData, glazingNote: "" });
    }
  };
  function importAll(r) {
    return r.keys().map(r);
  }
  const glassImages = importAll(
    require.context("../../../public/images/glass", false, /\.(png|jpe?g|svg)$/)
  );
  const doorOption = userData["doorOption"];
  const selectedTyp = require("../glazingSections/" + doorOption + ".png");
  const selectedTypGlass = require("../glassOnlySections/" +
    doorOption +
    ".png");
  var activeTyp = "";
  var fields = [];
  const { glazing1, glazing2, glazing3, glazing4 } = userData;
  const assignFields = (amount, callback) => {
    fields.push(
      <div style={{ display: "flex" }}>
        <div>
           {" "}
          <p class="glazingFieldTag" htmlFor="glazing4">
            a
          </p>
        </div>
        <div className="cc-selector-glazing" id="glazing1">
          {glassImages.map((image) => {
            var filename = getFilenameFromUrl(image.default);
            return (
              <div className="column">
                <input
                  id={"glazing1_" + image.default}
                  type="radio"
                  name="glazing1"
                  onChange={(e) => {
                    setUserData({ ...userData, glazing1: filename });
                    setUrlData({ ...urlData, glazingUrl4: image.default });
                  }}
                ></input>
                <label
                  className="drinkcard-cc-glazing"
                  htmlFor={"glazing1_" + image.default}
                  style={{ backgroundImage: `url(${image.default})` }}
                ></label>
                <p>{filename}</p>
              </div>
            );
          })}
          <div className="column">
            <label className="container-radio">
              Andere
              <input
                id="glazing1Other"
                type="radio"
                value="Other"
                name="glazing1"
                onChange={(e) => {
                  setUserData({ ...userData, glazing1: e.target.value });
                }}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    );
    fields.push(
      <div style={{ display: "flex" }}>
        <div>
           {" "}
          <p class="glazingFieldTag" htmlFor="glazing2">
            b
          </p>
        </div>
        <div className="cc-selector-glazing" id="glazing2">
          {glassImages.map((image) => {
            var filename = getFilenameFromUrl(image.default);
            return (
              <div className="column">
                <input
                  id={"glazing2_" + image.default}
                  type="radio"
                  name="glazing2"
                  onChange={(e) => {
                    setUserData({ ...userData, glazing2: filename });
                    setUrlData({ ...urlData, glazingUrl4: image.default });
                  }}
                ></input>
                <label
                  className="drinkcard-cc-glazing"
                  htmlFor={"glazing2_" + image.default}
                  style={{ backgroundImage: `url(${image.default})` }}
                ></label>
                <p>{filename}</p>
              </div>
            );
          })}
          <div className="column">
            <label className="container-radio">
              Andere
              <input
                id="glazing2Other"
                type="radio"
                value="Other"
                name="glazing2"
                onChange={(e) => {
                  setUserData({ ...userData, glazing2: e.target.value });
                }}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    );
    fields.push(
      <div style={{ display: "flex" }}>
        <div>
           {" "}
          <h5 class="glazingFieldTag" htmlFor="glazing3">
            c
          </h5>
        </div>
        <div className="cc-selector-glazing" id="glazing3">
          {glassImages.map((image) => {
            var filename = getFilenameFromUrl(image.default);
            return (
              <div className="column">
                <input
                  id={"glazing3_" + image.default}
                  type="radio"
                  name="glazing3"
                  onChange={(e) => {
                    setUserData({ ...userData, glazing3: filename });
                    setUrlData({ ...urlData, glazingUrl4: image.default });
                  }}
                ></input>
                <label
                  className="drinkcard-cc-glazing"
                  htmlFor={"glazing3_" + image.default}
                  style={{ backgroundImage: `url(${image.default})` }}
                ></label>
                <p>{filename}</p>
              </div>
            );
          })}
          <div className="column">
            <label className="container-radio">
              Andere
              <input
                id="glazing3Other"
                type="radio"
                value="Other"
                name="glazing3"
                onChange={(e) => {
                  setUserData({ ...userData, glazing3: e.target.value });
                }}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    );
    fields.push(
      <div style={{ display: "flex" }}>
        <div>
           {" "}
          <p class="glazingFieldTag" htmlFor="glazing4">
            d
          </p>
        </div>
        <div className="cc-selector-glazing" id="glazing">
          {glassImages.map((image) => {
            var filename = getFilenameFromUrl(image.default);
            return (
              <div className="column">
                <input
                  id={"glazing4_" + image.default}
                  type="radio"
                  name="glazing4"
                  onChange={(e) => {
                    setUserData({ ...userData, glazing4: filename });
                    setUrlData({ ...urlData, glazingUrl4: image.default });
                  }}
                ></input>
                <label
                  className="drinkcard-cc-glazing"
                  htmlFor={"glazing4_" + image.default}
                  style={{ backgroundImage: `url(${image.default})` }}
                ></label>
                <p>{filename}</p>
              </div>
            );
          })}
          <div className="column">
            <label className="container-radio">
              Andere
              <input
                id="glazing4Other"
                type="radio"
                value="Other"
                name="glazing4"
                onChange={(e) => {
                  setUserData({ ...userData, glazing4: e.target.value });
                }}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    );
    callback(amount, setFields);
  };
  const decideFieldAmount = (amount, callback) => {
    let tempArray = [];
    for (let i = 0; i < amount; i++) {
      tempArray[i] = fields[i];
    }
    callback(tempArray);
  };

  const setFields = (array) => {
    fields = array;
  };

  switch (doorOption) {
    case "typ_0":
      assignFields(1, decideFieldAmount);
      activeTyp = "typ_0";
      toCheckFields = [userData["glazing1"]];

      break;
    case "typ_1":
      assignFields(2, decideFieldAmount);
      toCheckFields = [userData["glazing1"], userData["glazing2"]];

      activeTyp = "typ_1";
      break;
    case "typ_2":
      assignFields(2, decideFieldAmount);
      toCheckFields = [userData["glazing1"], userData["glazing2"]];

      activeTyp = "typ_2";
      break;
    case "typ_3":
      assignFields(2, decideFieldAmount);
      toCheckFields = [userData["glazing1"], userData["glazing2"]];

      activeTyp = "typ_3";
      break;
    case "typ_4":
      assignFields(3, decideFieldAmount);
      toCheckFields = [
        userData["glazing1"],
        userData["glazing2"],
        userData["glazing3"],
      ];

      activeTyp = "typ_4";
      break;
    case "typ_5":
      assignFields(3, decideFieldAmount);
      toCheckFields = [
        userData["glazing1"],
        userData["glazing2"],
        userData["glazing3"],
      ];

      activeTyp = "typ_5";
      break;
    case "typ_6":
      assignFields(3, decideFieldAmount);
      toCheckFields = [
        userData["glazing1"],
        userData["glazing2"],
        userData["glazing3"],
      ];

      activeTyp = "typ_6";
      break;
    case "typ_7":
      assignFields(4, decideFieldAmount);
      toCheckFields = [
        userData["glazing1"],
        userData["glazing2"],
        userData["glazing3"],
        userData["glazing4"],
      ];

      activeTyp = "typ_7";
      break;
    default:
      break;
  }
  return (
    <Container maxWidth="md">
      <Typography variant="h3" color="secondary">
        Glaeser
      </Typography>
      <span className="requiredWarning">{error}</span>

      <Row xs="1" sm="2" md="4">
        <Col md="3">
          {userData["model"] !== "mit Glas" ? (
            <img
              src={selectedTyp.default}
              width="250"
              height="300"
              alt={selectedTyp.name}
            />
          ) : (
            <img
              src={selectedTypGlass.default}
              width="250"
              height="300"
              alt={selectedTyp.name}
            />
          )}
        </Col>

        <Col md="1">
          <div class="vl"></div>
        </Col>
        <Col md="8">
          {fields}
          <div style={{ marginTop: "2rem" }}>
            <h6 style={{ color: "#444" }}>Ausgewählt</h6>

            <div style={{ display: "flex" }}>
              {glazing1 != null ? <p>a: {glazing1}</p> : null}
              &nbsp; &nbsp;
              {glazing2 != null ? <p>b: {glazing2}</p> : null}
              &nbsp;&nbsp;
              {glazing3 != null ? <p>c: {glazing3}</p> : null}
              &nbsp;&nbsp;
              {glazing4 != null ? <p>d: {glazing4}</p> : null}
              &nbsp;&nbsp;
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <TextField
          label="Note"
          name="glazingNote"
          id="glazingNote"
          value={userData["glazingNote"] || ""}
          onChange={(e) =>
            setUserData({ ...userData, glazingNote: e.target.value })
          }
          margin="normal"
          variant="outlined"
          autoComplete="off"
          fullWidth
        />
      </Row>
      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <Button
            className="buttonBack"
            margin="normal"
            onClick={() => setStep(5)}
            variant="contained"
            color="Secondary"
          >
            {" "}
            Back{" "}
          </Button>
          <Button
            className="buttonNext"
            margin="normal"
            onClick={handleNext}
            variant="contained"
            color="primary"
          >
            {" "}
            Next{" "}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
