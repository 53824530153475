import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";

import BorderColorIcon from "@mui/icons-material/BorderColor";

import DashboardIcon from "@mui/icons-material/Dashboard";

import LogoutIcon from "@mui/icons-material/Logout";

import HomeIcon from "@mui/icons-material/Home";

import { ReactComponent as FlyScreenIcon } from "../assets/mosquito-from-top-view-svga.svg";

export const SidebarData = [
  {
    title: 'Unternehmen',
    path: '/',
    icon: <HomeIcon />,
    cName: 'nav-text'
  },
  {
    title: 'Produktionsverfolgung',
    path: '/production',
    icon: <BorderColorIcon />,
    cName: 'nav-text'
  },
  {
    title: 'Aludoor Konfigurator',
    path: '/aludoor',
    icon: <MeetingRoomIcon />,
    cName: 'nav-text'
  },
  {
    title: 'Fliegengitter',
    path: '/flyscreen',
    icon: <FlyScreenIcon />,
    cName: 'nav-text'
  },
  {
    title: 'Profil',
    path: '/dashboard',
    icon: <DashboardIcon />,
    cName: 'nav-text'
  },
  // {
  //   title: 'Orders',
  //   path: '/orders',
  //   icon: <DashboardIcon />,
  //   cName: 'nav-text'
  // },
  {
    title: 'Logout',
    path: '/logout',
    icon: <LogoutIcon />,
    cName: 'nav-text'
  }
];
