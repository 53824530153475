import React, { useState } from "react";
import { db } from "./firebase";
import firebase from "firebase";
import App from "./App"
import { useAuth } from './authentication/AuthContext';
export const multiStepContextAlu = React.createContext();
const StepContextAlu = () => {
  const [currentStep, setStep] = useState(0);
  const [userData, setUserData] = useState([]);
  const [urlData, setUrlData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [mailerState, setMailerState] = useState({});
  const [filledCheck, setFilledCheck] = useState(false);
  const [userFsData, setUserFsData] = useState([]);
  const [orderFsData, setOrderFsData] = useState([]);
const [finalFsData, setFinalFsData] = useState([]);
const [fsofr, setFsOfr] = useState(0);
const [table, setTable] = useState(false);
const [mailerFsState, setMailerFsState] =useState({customer: [],order: [],});
const [userCurrent, setUserCurrent] = useState();

  const submitEmail = async () => {
    const response = await fetch("https://plisemailer.herokuapp.com/sendalu", {
       mode: "cors",
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({ mailerState }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        const resData = await res;
        if (resData === "success") {
          alert("Message Sent");
        } else if (resData.status === "fail") {
          alert("Message failed to send");
        }
      })
      .then(() => {});
  };
  function submitData() {
    setFinalData(userData);
    try{
      submitEmail();


    }catch(e){
      alert(e);
    }
    setStep(0);
    db.collection("doors").add({
      submittedarray: userData,
      urlData:urlData,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      uid:userCurrent.uid
    });

    setUserData("");
    setUrlData("");
  }

function addToFinalFs() {

  setFinalFsData((finalFsData) => [...finalFsData, orderFsData]);
  setOrderFsData("");
}
const submitEmailFs = async () => {

  const response = await fetch("https://plisemailer.herokuapp.com/sendfs", {
  // const response = await fetch("https://localhost:3002/sendfs", {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({ mailerFsState }),
  })
    .then((res) => res.json())
    .then(async (res) => {
      const resData = await res;
      if (resData.status === "success") {
        alert("Message Sent");
      } else if (resData.status === "fail") {
        alert("Message failed to send");
      }
    })
    .then(() => {});
};
function subtotal(items) {
  return items
    .map(({ amount }) => parseInt(amount))
    .reduce((sum, i) => sum + i, 0);
}
function submitFsData() {

  var timeStamp = firebase.firestore.FieldValue.serverTimestamp();

  const totalAmount = subtotal(finalFsData);

  var docName =
    "" +
    userFsData["customerName"] +
    "_" +
    userFsData["commisionName"] +
    "_" +
    fsofr;
  db.collection("submittedflyscreen")
    .doc(docName)
    .set({
      submittedarray: {
        userData: userFsData,
        fullData: finalFsData,
        posTotal: totalAmount,
      },
      timeStamp,
      ofr: parseInt(fsofr),
      email: userFsData["email"],
    })
    .then(() => {
      db.collection("ofrcollection")
        .doc("ofrassign")
        .update({ ofr: parseInt(fsofr) + 1 });

      alert("Successfully Added");
    });

  submitEmailFs();
  setFinalFsData("");
  setUserFsData("");
  setOrderFsData("");
  setMailerFsState("");
}
  return (
    <div>
      <multiStepContextAlu.Provider
        value={{
          currentStep,
          setStep,
          userData,
          setUserData,
          urlData,
          setUrlData,
          finalData,
          setFinalData,
          submitData,
          setMailerState,
          filledCheck,
          setFilledCheck,
          orderFsData,
          setOrderFsData,
          userFsData,
          setUserFsData,
          finalFsData,
          setFinalFsData,
          submitFsData,
          addToFinalFs,
          table,
          setTable,
          setMailerFsState,
          fsofr,
          setFsOfr,
          userCurrent,
          setUserCurrent

        }}
      >
        <App/>
      </multiStepContextAlu.Provider>
    </div>
  );
};
export default StepContextAlu;
