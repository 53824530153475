import React, { useContext, useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { Container, Row, Col } from "reactstrap";
import { multiStepContextAlu } from "../../StepContext";
import "../styles/Color.scss";
import { checkRequiredFields, getFilenameFromUrl } from "../functions";
export default function Hstep() {
  const { setStep, userData, setUserData, urlData, setUrlData } =
    useContext(multiStepContextAlu);
  const ralColorArr = [
    //#region ralcolors

    "ral7016",
    "ral9016",
    "ral1000",
    "ral1001",
    "ral1002",
    "ral1003",
    "ral1004",
    "ral1005",
    "ral1006",
    "ral1007",
    "ral1011",
    "ral1012",
    "ral1013",
    "ral1014",
    "ral1015",
    "ral1016",
    "ral1017",
    "ral1018",
    "ral1019",
    "ral1020",
    "ral1021",
    "ral1023",
    "ral1024",
    "ral1026",
    "ral1027",
    "ral1028",
    "ral1032",
    "ral1033",
    "ral1034",
    "ral1035",
    "ral1036",
    "ral1037",
    "ral2000",
    "ral2001",
    "ral2002",
    "ral2003",
    "ral2004",
    "ral2005",
    "ral2007",
    "ral2008",
    "ral2009",
    "ral2010",
    "ral2011",
    "ral2012",
    "ral2013",
    "ral3000",
    "ral3001",
    "ral3002",
    "ral3003",
    "ral3004",
    "ral3005",
    "ral3007",
    "ral3009",
    "ral3011",
    "ral3012",
    "ral3013",
    "ral3014",
    "ral3015",
    "ral3016",
    "ral3017",
    "ral3018",
    "ral3020",
    "ral3022",
    "ral3024",
    "ral3026",
    "ral3027",
    "ral3028",
    "ral3031",
    "ral3032",
    "ral3033",
    "ral4001",
    "ral4002",
    "ral4003",
    "ral4004",
    "ral4005",
    "ral4006",
    "ral4007",
    "ral4008",
    "ral4009",
    "ral4010",
    "ral4011",
    "ral4012",
    "ral5000",
    "ral5001",
    "ral5002",
    "ral5003",
    "ral5004",
    "ral5005",
    "ral5007",
    "ral5008",
    "ral5009",
    "ral5010",
    "ral5011",
    "ral5012",
    "ral5013",
    "ral5014",
    "ral5015",
    "ral5017",
    "ral5018",
    "ral5019",
    "ral5020",
    "ral5021",
    "ral5022",
    "ral5023",
    "ral5024",
    "ral5025",
    "ral5026",
    "ral6000",
    "ral6001",
    "ral6002",
    "ral6003",
    "ral6004",
    "ral6005",
    "ral6006",
    "ral6007",
    "ral6008",
    "ral6009",
    "ral6010",
    "ral6011",
    "ral6012",
    "ral6013",
    "ral6014",
    "ral6015",
    "ral6016",
    "ral6017",
    "ral6018",
    "ral6019",
    "ral6020",
    "ral6021",
    "ral6022",
    "ral6024",
    "ral6025",
    "ral6026",
    "ral6027",
    "ral6028",
    "ral6029",
    "ral6032",
    "ral6033",
    "ral6034",
    "ral6035",
    "ral6036",
    "ral6037",
    "ral6038",
    "ral7000",
    "ral7001",
    "ral7002",
    "ral7003",
    "ral7004",
    "ral7005",
    "ral7006",
    "ral7008",
    "ral7009",
    "ral7010",
    "ral7011",
    "ral7012",
    "ral7013",
    "ral7015",
    "ral7021",
    "ral7022",
    "ral7023",
    "ral7024",
    "ral7026",
    "ral7030",
    "ral7031",
    "ral7032",
    "ral7033",
    "ral7034",
    "ral7035",
    "ral7036",
    "ral7037",
    "ral7038",
    "ral7039",
    "ral7040",
    "ral7042",
    "ral7043",
    "ral7044",
    "ral7045",
    "ral7046",
    "ral7047",
    "ral7048",
    "ral8000",
    "ral8001",
    "ral8002",
    "ral8003",
    "ral8004",
    "ral8007",
    "ral8008",
    "ral8011",
    "ral8012",
    "ral8014",
    "ral8015",
    "ral8016",
    "ral8017",
    "ral8019",
    "ral8022",
    "ral8023",
    "ral8024",
    "ral8025",
    "ral8028",
    "ral8029",
    "ral9001",
    "ral9002",
    "ral9003",
    "ral9004",
    "ral9005",
    "ral9006",
    "ral9007",
    "ral9010",
    "ral9011",
    "ral9016",
    "ral9017",
    "ral9018",
    "ral9022",
    "ral9023",
    //#endregion
  ];
  const [ralColors, setRalColors] = useState(ralColorArr);

  const [error, seterror] = useState("");
  const [required, setrequired] = useState(false);
  useEffect(() => {
    const check = checkRequiredFields([
      userData["incolor"],
      userData["outcolor"],
    ]);
    setrequired(check);
  });
  const handleNext = () => {
    required ? setStep(8) : seterror(`Fields cannot be empty`);
  };

  var counter = 0;
  function importAll(r) {
    return r.keys().map(r);
  }
  const newadecColors = importAll(
    require.context("../../../public/colors/adec", false, /\.(png|jpe?g|svg)$/)
  );
  return (
    <Container maxWidth="lg">
      <span className="requiredWarning">{error}</span>

      <Row>
        <Col sm="12" md="5" lg="5">
          <h1> Innen Farbe</h1>
          <div class="cc-selector-color">
            {newadecColors.map((image) => {
              var filename = getFilenameFromUrl(image.default);
              return (
                <div className="column">
                  <input
                    id={image.default}
                    type="radio"
                    name="innercolor"
                    onChange={(e) => {
                      setUserData({ ...userData, incolor: filename });
                      setUrlData({ ...urlData, incolorUrl: image.default });
                    }}
                  ></input>
                  <label
                    className="drinkcard-cc-color"
                    htmlFor={image.default}
                    style={{ backgroundImage: `url(${image.default})` }}
                  ></label>
                  <p>{filename}</p>
                </div>
              );
            })}
          </div>

          <div class="cc-selector-color">
            {ralColors.map((color) => {
              return (
                <div className={"columnColor " + color}>
                  <input
                    id={color}
                    type="radio"
                    name="innercolor"
                    onChange={(e) => {
                      setUserData({ ...userData, incolor: color });
                      setUrlData({ ...urlData, incolorUrl: color });
                    }}
                  ></input>
                  <label className="drinkcard-cc-color" htmlFor={color}></label>
                  <p> {color}</p>
                </div>
              );
            })}
          </div>
        </Col>
        <Col sm="12" md="5" lg="5">
          <h1>Außen Farbe</h1>
          <div class="cc-selector-color">
            {newadecColors.map((image) => {
              var filename = getFilenameFromUrl(image.default);

              return (
                <div className="column">
                  <input
                    id={"out_" + image.default}
                    type="radio"
                    name="outercolor"
                    onChange={(e) => {
                      setUserData({ ...userData, outcolor: filename });
                      setUrlData({ ...urlData, outcolorUrl: image.default });
                    }}
                  ></input>
                  <label
                    className="drinkcard-cc-color"
                    htmlFor={"out_" + image.default}
                    style={{ backgroundImage: `url(${image.default})` }}
                  ></label>
                  <p>{filename}</p>
                </div>
              );
            })}
          </div>

          <div class="cc-selector-color">
            {ralColors.map((color) => {
              return (
                <div className={"columnColor " + color}>
                  <input
                    id={"out_" + color}
                    type="radio"
                    name="outercolor"
                    onChange={(e) => {
                      setUserData({ ...userData, outcolor: color });
                      setUrlData({ ...urlData, outcolorUrl: color });
                    }}
                  ></input>
                  <label
                    className="drinkcard-cc-color"
                    htmlFor={"out_" + color}
                  ></label>
                  <p> {color}</p>
                </div>
              );
            })}
          </div>
        </Col>
        <Col sm="12" md="2" lg="2">
          {required ? (
            <div>
              <h6 style={{ color: "#444" }}>Ausgewählt</h6>
              <p style={{ width: "140px" }}>
                Innen: {userData["incolor"]}
              </p>{" "}
              <p style={{ width: "140px" }}>Außen: {userData["outcolor"]}</p>
            </div>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <Button
              className="buttonBack"
              margin="normal"
              onClick={() => setStep(6)}
              variant="contained"
              color="Secondary"
            >
              {" "}
              Back{" "}
            </Button>
            <Button
              className="buttonNext"
              margin="normal"
              onClick={handleNext}
              variant="contained"
              color="primary"
            >
              {" "}
              Next{" "}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
