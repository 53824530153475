
import { Stepper, StepLabel, Step } from "@material-ui/core";
import { multiStepContextAlu } from "../StepContext";
import { useContext } from "react";
import { Button } from "@material-ui/core";
import Astep from './steps/Astep';
import Bstep from './steps/Bstep';
import Cstep from './steps/Cstep';
import Dstep from './steps/Dstep';
import Estep from './steps/Estep';
import Fstep from './steps/Fstep';
import Gstep from './steps/Gstep';
import Hstep from './steps/Hstep';
import Istep from './steps/Istep';
import Jstep from './steps/Jstep';
import Kstep from './steps/Kstep';
import Lstep from './steps/Lstep';
import Mstep from './steps/Mstep';

function MainPageAlu() {
  const { currentStep, setStep, filledCheck } =
    useContext(multiStepContextAlu);
  function showStep(step) {
    switch (step) {
      case 0:
        return <Astep />;
      case 1:
        return <Bstep />;
      case 2:
        return <Cstep />;
      case 3:
        return <Dstep />;
      case 4:
        return <Estep />;
      case 5:
        return <Fstep />;
      case 6:
        return <Gstep />;
      case 7:
        return <Hstep />;
      case 8:
        return <Istep />;
      case 9:
        return <Jstep />;
      case 10:
        return <Kstep />;
      case 11:
        return <Lstep />;
      case 12:
        return <Mstep />;
      default:
        return <Astep />;
    }

  }
  return (
    <header className="App-header">
        <Stepper
          style={{ width: "%18",backgroundColor:"#fafafa" }}
          activeStep={currentStep}
          orientation="horizontal"
        >
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
        </Stepper>
      {showStep(currentStep)}
      {filledCheck && currentStep !== 12 ? (
        <Button
        
          margin="normal"
          onClick={() => {setStep(12)}}
          variant="contained"
          color="primary"
          style={{ marginTop: "10px",backgroundColor:"#333" }}
          className="btn-hover color-check "
        >
          {" "}
          Check{" "}
        </Button>
      ) : null}
    </header>
  );
}

export default MainPageAlu;
