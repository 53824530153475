import React, { useContext, useState, useEffect } from "react";
import { Button, TextField } from "@material-ui/core";
import { multiStepContextAlu } from "../../StepContext";
import { Container, Row, Col } from "reactstrap";

export default function Lstep() {
  const {
    setStep,
    userData,
    setUserData,
    urlData,
    setMailerState,
    setFilledCheck
  } = useContext(multiStepContextAlu);
  const [error, seterror] = useState("");

  return (
    <Container maxWidth="md">
      <span className="requiredWarning">{error}</span>

      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <p>Note</p>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="5"
            value={userData["note"]}
            onChange={(e) => {
              setUserData({ ...userData, note: e.target.value });
            }}
          ></textarea>
        </Col>
      </Row>
      <Button
        className="buttonBack"
        margin="normal"
        onClick={() => setStep(10)}
        variant="contained"
        color="Secondary"
      >
        {" "}
        Back{" "}
      </Button>
      <Button
        className="buttonBack"
        margin="normal"
        onClick={() => {     setMailerState({
          name: urlData,
          email: "tersfilozof@gmail.com",
          message: userData,
        }); setStep(12);setFilledCheck(true) }}
        variant="contained"
        color="primary"
      >
        {" "}
        Next{" "}
      </Button>
    </Container>
  );
}
