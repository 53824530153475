import "./App.css";
import React from "react";
import { createMuiTheme, ThemeProvider, CssBaseline } from "@material-ui/core";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import ForgotPassword from "./authentication/ForgotPassword";
import PrivateRoute from "./authentication/PrivateRoute";
import PrivateRouteAdmin from "./authentication/PrivateRouteAdmin";
import Login from "./authentication/Login";
import { AuthProvider } from "./authentication/AuthContext";
import SignUp from "./authentication/signup";

import Mainpage from "./Mainpage";
import MainPageAlu from "./aludoor/MainPageAlu";
import MainPageFs from "./flyscreen/mainpage";
import Sheets from "./production/Sheets";
import UpdateProfile from "./authentication/UpdateProfile";
import Dashboard from "./authentication/Dashboard";
import Helper from "./authentication/Helper";
import AllUsers from "./Admin/AllUsers";
import CompletedOrders from './Admin/AllCompleted';
import Admin from './Admin/Index';
import DoorOrders from './Admin/DoorOrders';
import PDF from "./aludoor/pdf";
import AdminPdf from './Admin/AdminPdf'
import AludoorOrders from './Orders/AludoorOrders';
const theme = createMuiTheme({
  palette: {
    background: {
      xs: "red",
      sm: "blue",
      md: "green",
    },
    primary: {
      main: "#008ccb",
    },
    secondary: {
      main: "#545961",
    },
  },
});

function App() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div
        className="App"
        style={
          {
            // backgroundColor: 'blue',
          }
        }
      >
        <AuthProvider>
          <Router>
            <div>
              <Switch>
                <AuthProvider>
                  <div style={{ display: "flex"}}>
                    <Helper  />
                    <div
                      style={{
                        paddingTop: "80px",
                        marginLeft:"120px",
                        width: "80%",
                        height: "100%",
                        zIndex:"0"
                      }}
                    >
                      <Switch>
                        <PrivateRoute exact path="/" component={Mainpage} />
                        <PrivateRoute
                          exact
                          path="/aludoor"
                          component={MainPageAlu}
                        />
                        <PrivateRoute
                          exact
                          path="/flyscreen"
                          component={MainPageFs}
                        />
                        <PrivateRoute path="/production" component={Sheets} />
                        <PrivateRoute
                          exact
                          path="/update-profile"
                          component={UpdateProfile}
                        />
                        <PrivateRoute
                          exact
                          path="/orders"
                          component={AludoorOrders}
                        />
                        
                     
                        <PrivateRoute path="/dashboard" component={Dashboard} />

                        <PrivateRoute path="/signup" component={SignUp} />
                        <PrivateRouteAdmin path="/admin" component={Admin} />
                        <PrivateRouteAdmin path="/doororders" component={DoorOrders} />
                        <PrivateRouteAdmin path="/pdf" component={AdminPdf} />
                        <PrivateRouteAdmin
                          exact
                          path="/allusers"
                          component={AllUsers}
                        />
                        <PrivateRouteAdmin
                          exact
                          path="/completedorders"
                          component={CompletedOrders}
                        />
                        <Route path="/login" component={Login} />
                        <Route
                          path="/forgot-password"
                          component={ForgotPassword}
                        />
                      </Switch>
                    </div>
                  </div>
                </AuthProvider>
              </Switch>
            </div>
          </Router>
        </AuthProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
