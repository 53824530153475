import React, { useRef,useState} from 'react'
import { Form,FormGroup,Label,Input,Button,Card,CardBody, CardTitle,Container, Alert,Row,Col} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';

export default function ForgotPassword(){

    const [input, setInput] = useState('')
    const {resetPassword} = useAuth();
    const [error, setError] = useState('');
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    async function handleSubmit(e){
        e.preventDefault();

     
        try{
            setError('');
            setMessage('');
            setLoading(true)
            await resetPassword(input.email)
            setMessage('Check your inbox for further instructions')
        }catch{
            setError('Failed to reset Password')
        }
        setLoading(false);
    }
    return (
        <>
        <Row style={{marginTop:"10rem"}}>
            <Col sm="12" md={{ size: 6, offset: 3 }}>
        <Card >
            <CardBody>
            <CardTitle tag="h5">Password Reset</CardTitle>
            {error&& <Alert color="danger">{error}</Alert>   }
            {message&& <Alert color="success">{message}</Alert>   }
                <Form onSubmit={handleSubmit}>
                    <FormGroup id="email" >
                        <Label >Email</Label >
                        <Input type="email" onChange={(event)=>
                                setInput({ ...input, email: event.target.value})

                        }  required/>
                    </FormGroup>
                
         
                    <Button disabled={loading} color="primary" type="submit" style={{marginTop:"1rem"}}>Reset Password</Button>
                </Form>
                <div>
                    <Link to="/login">Log In</Link>
                </div>
            </CardBody>
            {/* <div className="w-100 text-center mt-2">
                Dont have an account? <Link to="/signup">Sign Up</Link>
            </div> */}
        </Card>
        </Col>
        </Row>
        </>

    )
}