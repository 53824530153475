import React, { useContext, useState, useEffect } from "react";
import { Button, TextField,Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { multiStepContextAlu } from "../../StepContext";
import "../styles/allstyle.scss"

import { checkRequiredFields } from "../functions";
import logo from "../../logo_gw.png";
import { useAuth } from "../../authentication/AuthContext";
export default function Astep() {
  const { currentStep,setStep, userData, setUserData,setUserCurrent } = useContext(multiStepContextAlu);
  const [error, seterror] = useState("");
  const [state, setstate] = useState(true);
  const { currentUser } = useAuth();
  setUserCurrent(currentUser);
  useEffect(() => {
    const check = checkRequiredFields([
      userData["commisionName"],
    ]);
    setstate(check);
  });
  const handleNext = () => {
    state ? setStep(currentStep+1) : seterror(`Fields cannot be empty`);
  };
  return (
    <Container maxWidth="lg">
          <div style={{display:'flex'}}>
              <img src={logo} width="150" height="80" alt={logo} style={{marginLeft:200}}></img>
              <Typography variant="h3" color="primary" style={{marginLeft:150}}>
              Aluminium Türen
              </Typography>
              </div>
      <span className="requiredWarning">{error}</span>
      <p style={{ textAlign: "left" }}>
       <strong>Name:</strong>
              {currentUser.displayName}
            </p>
            <p style={{ textAlign: "left" }}>
              <strong>Email:</strong>
              {currentUser.email}
            </p>
      <TextField
        label="Kommission"
        name="commisionName"
        value={userData["commisionName"] || ""}
        onChange={(e) => {

          setUserData({ ...userData, commisionName: e.target.value, customerName:currentUser.displayName, email: currentUser.email});
        }}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
      />
    
      <div>
        <Button
          className="buttonNext"
          margin="normal"
          onClick={handleNext}
          variant="contained"
          color="primary"
        >
          {" "}
          Next{" "}
        </Button>
      </div>
    </Container>
  );
}
