import React, { useContext, useState, useEffect } from "react";
import { Button, Typography } from "@material-ui/core";
import { Container, Row, Col } from "reactstrap";
import Alert from "@material-ui/lab/Alert";
import { checkRequiredFields } from "../functions";
import { multiStepContextAlu } from "../../StepContext";
import "../styles/model.css";
import {
  glasslines,
  ecolines,
  prestigelines,
  glassOnly,
} from "../StepFiles/thirdStepfiles";

import ModalImage from "react-modal-image";

export default function Estep() {
  const [items, setItems] = useState([]);
  const [itemName, setItemName] = useState("");
  const [error, seterror] = useState("");
  const [required, setrequired] = useState(false);
  const [largeImages, setLargeImages] = useState("");
  useEffect(() => {
    const check = checkRequiredFields([userData["model"]]);
    setrequired(check);
  });
  const handleNext = () => {
    required ? setStep(currentStep + 1) : seterror(`Fields cannot be empty`);
  };
  const addItem = () => {
    setItems([
      ...items,
      {
        id: items.length,
        name: itemName,
      },
    ]);
    setItemName("");
  };

  const { currentStep, setStep, userData, setUserData, urlData, setUrlData } =
    useContext(multiStepContextAlu);

  function importImages(array, name) {
    var imageArray = [];
    array.map((element) => {
      imageArray.push([element, require("../models/" + name + "/" + element)]);
    });
    return imageArray;
  }
  const glassLineImages = importImages(glasslines, "glassline");
  const ecolineImages = importImages(ecolines, "ecoline");
  const prestigelineImages = importImages(prestigelines, "prestigeline");
  const glassOnlyImages = importImages(glassOnly, "glassOnly");



  function getModelName(filename) {
    let array = filename.split(".");
    let modelname = array[0];
    return modelname;
  }
  function insertModels(images) {
    return (
      <div className="modelRow">
        {images.map((image) => {
          var modelname = getModelName(image[0]);
          return (
            <div className="column">
              <input
                id={modelname}
                type="radio"
                name="doorModel"
                value={modelname}
                onChange={(e) => {
                  setUserData({ ...userData, model: modelname });
                  setUrlData({
                    ...urlData,
                    modelUrl: image[1].default,
                  });
                  setLargeImages(
                    "https://firebasestorage.googleapis.com/v0/b/konfigurator-gw.appspot.com/o/images%2Fveyna%2Feconomicline%2F" +
                      modelname +
                      ".png?alt=media"
                  );
                }}
              ></input>
              <label
                className="drinkcard-cc-model"
                htmlFor={modelname}
                style={{
                  backgroundImage: `url(${image[1].default})`,
                }}
              ></label>
              <p className="model-name">{modelname}</p>
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <Container maxWidth="lg">
      <Row className="warning" style={{ paddingBottom: 55 }}>
        <Col>
          <Alert severity="error">
            Diese Fotos zeigen nur die Modelle und stellen nicht die Farben und
            Zubehör dar.
          </Alert>
        </Col>
        <Col>
          <Typography variant="h4" color="secondary">
            Tür Modelle
          </Typography>
        </Col>

        <Col>
          <Row>
            <Col>
              {required ? (
                <div style={{ maxWidth: "60px", maxHeight: "100px" }}>
                  <h6 style={{ color: "#444" }}>Ausgewählt</h6>
                  <ModalImage
                    small={urlData["modelUrl"]}
                    large={largeImages}
                    alt={userData["model"]}
                  />
                  <p>{userData["model"]}</p>
                </div>
              ) : null}
            </Col>
            <Col>
              {required ? (
                <Alert color="info" severity="info">
                  zum Vergrößern auf das Bild klicken
                </Alert>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
      <span className="requiredWarning">{error}</span>
      <div>
        <form>
          <Row>
            <Col xs="10">
              <h3 style={{ color: "black" }}>mit Panel</h3>
              <div className="cc-selector-model">
                <h1 style={{ textAlign: "center" }}>Prestige Line</h1>

                {insertModels(prestigelineImages)}
                <h1 style={{ textAlign: "center" }}>Lux Line</h1>

                {insertModels(ecolineImages)}
                <h1 style={{ textAlign: "center" }}>Glass Line</h1>

                {insertModels(glassLineImages)}
              </div>
            </Col>
            <Col xs="2">
              <h3 style={{ color: "black" }}>mit Glas</h3>
              <div className="cc-selector-model" style={{ overflow: "hidden" }}>
                {insertModels(glassOnlyImages)}
              </div>
            </Col>
          </Row>
        </form>
        <Row>
          <Col sm="12" md={{ size: 6, offset: 3 }}>
            <Button
              className="buttonBack"
              margin="normal"
              onClick={() => setStep(currentStep - 1)}
              variant="contained"
              color="Secondary"
            >
              {" "}
              Back{" "}
            </Button>

            <Button
              className="buttonNext"
              margin="normal"
              onClick={handleNext}
              variant="contained"
              color="primary"
            >
              {" "}
              Next{" "}
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
