export var rosettes=[
"Rosette_Eckig.png",
"Rosette_Oval.png",
"Rundrosette.png",
"Schutzrosette_mit_Zylinderabdeckung_ZA1.png"
];
export var locks=[
    "Standard-3Fach_Verriegelung_Schwenkhakenschloss-TYP-3.png",
    "3Fach_Verriegelung_Automatic.png",
    "5-Fach_Hacken_Verrieglung.png",
    "Fingerprint_IDENCOM_mit_WINKHAUS_Motor-Verrieglung-L.png",
];
