import React from "react";
import { useAuth } from "./AuthContext";
import Navbar from '../navbar/Navbar';
export default function Helper() {
    const { currentUser} = useAuth();

        
    return(
        <div>
          {currentUser!=null?<Navbar/>:null}
        </div>
    )
}


