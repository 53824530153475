import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pdf from "react-to-pdf";
import { Button, Typography } from "@material-ui/core";
import { multiStepContextAlu } from "../StepContext";
import { Container, Row, Col, Table } from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import './styles/allstyle.scss'
import logo from "../../src/logo_gw.png";
const ref = React.createRef();
const options = {
  orientation: "portrait",
  unit: "in",
  format: [20, 10],
};
const useStyles = makeStyles((theme) => ({
  image: {
    marginLeft: 15,
  },
  title: {
    color: "secondary",
  },

  header: {
    color: "#000000",
  },
  headerRow: {
    height: 100,
    marginLeft: 70,
  },
  outhandle: {
    display: "column",
  },
  dirOptionRow: {
    marginBottom: 15,
  },
}));

const PDF = (props) => {
  const { userData, urlData, submitData, setStep } =
    useContext(multiStepContextAlu);
  const classes = useStyles();

  const {
    customerName,
    commisionName,
    doorOption,
    doorOptionWithLetters,
    doorOptionGlass,
    dimension1,
    dimension2,
    dimension3,
    dimension4,
    dimension5,
    email,
    openingIn,
    openingOut,
    perspective,
    model,
    panel,
    incolor,
    outcolor,
    glazing1,
    glazing2,
    glazing3,
    glazing4,
    inhandle,
    outhandle,
    outhandleSize,
    rosette,
    lock,
    camera,
    doorphone,
    note,
    openingStr,
    ots,
    eoffner
  } = props.userData;
  const {
    modelUrl,
    otsUrl,
    eoffnerUrl,
    panelUrl,
    inhandleUrl,
    outhandleUrl,
    rosetteUrl,
    lockUrl,
    cameraUrl,
    doorphoneUrl,
  } = props.urlData;

  return (
    <>
      <div className="post" ref={ref} >
        <Container className="themed-container" fluid={true}>
          <Row>
            <Col md={4}>
              <Row className={classes.headerRow}>
                <img src={logo} width="150" height="80" alt={inhandle}></img>
              </Row>
              <Typography variant="h5" className={classes.header}>
                Dimensionen
              </Typography>
              <Row>
                <Col>
                  <img
                    width="90px"
                    height="150px"
                    src={doorOptionWithLetters}
                    alt={doorOption}
                  ></img>
                </Col>
                <Col>
                  <div>
                    {dimension1 != null ? <p>a: {dimension1}</p> : null}
                    {dimension2 != null ? <p>b: {dimension2}</p> : null}
                    {dimension3 != null ? <p>c: {dimension3}</p> : null}
                    {dimension4 != null ? <p>d:{dimension4}</p> : null}
                    {dimension5 != null ? <p>d: {dimension5}</p> : null}
                  </div>
                </Col>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => setStep(2)}
                >
                  <Edit />
                </IconButton>
              </Row>
              <Typography variant="h5" className={classes.header}>
                Din-Richtung
              </Typography>

              <Row className={classes.dirOptionRow}>
                <Col md={6}>
                  <img
                    src={openingIn}
                    width="120"
                    height="200"
                    alt={doorOption}
                  ></img>
                  <p class="OpeningStrStyle">{openingStr[1]}</p>

                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={() => setStep(3)}
                  >
                    <Edit />
                  </IconButton>
                </Col>
                <Col md={6}>
                  <img
                    src={openingOut}
                    width="120"
                    height="200"
                    alt={doorOption}
                  ></img>
                  <p class="OpeningStrStyle">{openingStr[0]}</p>
                </Col>
              </Row>

              <Typography variant="h5" className={classes.header}>
                Systeme
              </Typography>
              <div>
              <img src={panelUrl} width="120" alt={panel}></img>
                <p>{panel}</p>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => setStep(5)}
                >
                  <Edit />
                </IconButton>
              </div>

              <Typography variant="h5" className={classes.header}>
                Verriegelunge
              </Typography>
              <Row>
                <Col> 
                <div>
           <img
                        src={urlData["lockUrl"]}
                        width="70"
                        height="200"
                        alt={userData["lock"]}
                      ></img>
                      </div>
                <p >{lock}</p>
                </Col>
              </Row>

              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => setStep(9)}
              >
                <Edit />
              </IconButton>
              <Row>
                <Col>
                  <img
                    width="90px"
                    height="150px"
                    src={doorOptionGlass}
                    alt={doorOption}
                  ></img>
                </Col>
                <Col>
                  <div>
                    {glazing1 != null ? <p>a: {glazing1}</p> : null}
                    {glazing2 != null ? <p>b: {glazing2}</p> : null}
                    {glazing3 != null ? <p>c: {glazing3}</p> : null}
                    {glazing4 != null ? <p>d: {glazing4}</p> : null}
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={8}>
              <Row className={classes.headerRow}>
                <Typography variant="h3" color="primary">
                  Aluminium Türen
                </Typography>
              </Row>
              <Table striped bordered hover size="sm">
                <tbody>
                  <tr>
                    <td>Kunde</td>
                    <td>{customerName}</td>
                  </tr>
                  <tr>
                    <td>Kommission</td>
                    <td>
                      {commisionName}{" "}
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => setStep(0)}
                      >
                        <Edit />
                      </IconButton>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{email} </td>
                  </tr>

                  <tr>
                    <td>Tür Modelle</td>
                    <td>
                      <img src={modelUrl} width="50" height="100"></img>
                      <h5>{model}</h5>
                      <h5>{perspective}</h5>
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => setStep(4)}
                      >
                        <Edit />
                      </IconButton>
                    </td>
                  </tr>

                  <tr>
                    <td>Farbe</td>
                    <td>
                      <h5>Innen:{incolor}</h5>
                      <h5>Aussen:{outcolor}</h5>{" "}
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => setStep(7)}
                      >
                        <Edit />
                      </IconButton>
                    </td>
                  </tr>

                  <tr>
                    <td>Innendruecker </td>
                    <td>
                      <img
                        src={inhandleUrl}
                        width="80"
                        height="120"
                        alt={inhandle}
                      ></img>
                      <p>{inhandle}</p>{" "}
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => setStep(8)}
                      >
                        <Edit />
                      </IconButton>
                    </td>
                  </tr>
                  <tr>
                    <td>Aussengriffe </td>
                    <td className={classes.outhandle}>
                      <img
                        src={outhandleUrl}
                        width="150"
                        height="150"
                        alt={outhandle}
                      ></img>
                      <p>
                        {outhandle},&nbsp;
                        {outhandleSize}
                      </p>{" "}
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => setStep(8)}
                      >
                        <Edit />
                      </IconButton>
                    </td>
                  </tr>

                  <tr>
                    <td>Rosetten </td>
                    <td>
                      <img
                        src={rosetteUrl}
                        width="40"
                        height="80"
                        alt={rosette}
                      ></img>
                      <p>{rosette}</p>{" "}
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => setStep(9)}
                      >
                        <Edit />
                      </IconButton>
                    </td>
                  </tr>
                  <tr>
                    <td>Kamera </td>
                    <td>
                      {camera ? (
                        <img
                          src={cameraUrl}
                          width="150"
                          height="120"
                          alt={camera}
                        ></img>
                      ) : (
                        <p>-------</p>
                      )}
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => setStep(10)}
                      >
                        <Edit />
                      </IconButton>
                    </td>
                  </tr>
                  <tr>
                    <td>E-Offner </td>
                    <td>
                      {eoffner ? (
                        <img
                          src={eoffnerUrl}
                          width="50"
                          height="75"
                          alt={eoffner}
                        ></img>
                      ) : (
                        <p>-------</p>
                      )}
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => setStep(10)}
                      >
                        <Edit />
                      </IconButton>
                    </td>
                  </tr>
                  <tr>
                    <td>OTS </td>
                    <td>
                      {ots ? (
                        <img
                          src={otsUrl}
                          width="50"
                          height="75"
                          alt={ots}
                        ></img>
                      ) : (
                        <p>-------</p>
                      )}
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => setStep(10)}
                      >
                        <Edit />
                      </IconButton>
                    </td>
                  </tr>
                  <tr>
                    <td>Tür Hörer </td>
                    <td>
                      {doorphone ? (
                        <img
                          src={doorphoneUrl}
                          width="50"
                          height="75"
                          alt={doorphone}
                        ></img>
                      ) : (
                        <p>-------</p>
                      )}
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => setStep(10)}
                      >
                        <Edit />
                      </IconButton>
                    </td>
                  </tr>
                  <tr>
                    <td>Note </td>
                    <td>
                      <h6>{note}</h6>
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => setStep(11)}
                      >
                        <Edit />
                      </IconButton>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
      <Pdf targetRef={ref} filename={commisionName} options={options}>
        {({ toPdf }) => (
          <Button
            margin="normal"
            onClick={toPdf}
            variant="contained"
            color="default"
          >
            Download as PDF
          </Button>
        )}
      </Pdf>
    </>
  );
};

export default PDF;
